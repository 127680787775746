import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(public router: Router) {}
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let user = localStorage.getItem("bearer");
    // console.log(user)
    if (user && user != "undefined") {
      return true;
    } else {
      this.router.navigateByUrl("pages/landingPage");
    }
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let user = localStorage.getItem("bearer");
    // console.log(user)
    if (user && user != "undefined") {
      return true;
    } else {
      this.router.navigateByUrl("pages/landingPage");
    }
  }
}
