import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { ToastrService } from "ngx-toastr";
import { ThemeOptions } from "../../../../../theme-options";

@Component({
  selector: "app-user-box",
  templateUrl: "./user-box.component.html",
})
export class UserBoxComponent implements OnInit {
  user: any;
  faCalendar = faCalendar;

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(
    public globals: ThemeOptions,
    private router: Router,
    public toasterService: ToastrService,
  ) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log("Userrrrr::" + this.user);
  }

  logout() {
    // console.log("inside logout")
    localStorage.removeItem("bearer");
    localStorage.removeItem("user");
    this.router.navigateByUrl("pages/login");
    this.toasterService.success("you've been logged out successfully", "Success", {
      positionClass: "toast-top-right",
    });
  }

  hasRole(type) {
    return JSON.parse(localStorage.getItem("user"))?.userType == type;
  }

  redirectToKYCDashboard() {
    this.router.navigate(["app", "pages", "customer", "KYCList"]);
  }
  redirectToUserProfile() {
    this.router.navigate(["app", "pages", "customer", "profile"]);
  }
  redirectToMyRemittances() {
    this.router.navigate(["app", "pages", "customer", "transList"]);
  }

  onEdit($event) {}
}
