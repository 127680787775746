<ng-select
  [items]="items"
  bindLabel="value"
  bindValue="id"
  (change)="change($event)"
  [(ngModel)]="value"
  [class]="class"
  [placeholder]="placeholder"
>
</ng-select>
