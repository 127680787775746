import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@env/environment";
import { ToastrService } from "ngx-toastr";

import { Observable, of, throwError } from "rxjs";
import { tap, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    public toasterService: ToastrService,
    private router: Router,
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log("inside", req.url);
    if (req.url.startsWith(environment.api.baseurl) && req.url.indexOf("/open/") != 0) {
      // console.log("inside interceptor");
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem("bearer")}`,
          // 'Access-Control-Allow-Origin': `*`,
        },
      });
    }
    return next.handle(req).pipe(
      catchError((err: any) => {
        console.log(err);
        if (err instanceof HttpErrorResponse && err.status == 401) {
          console.log("logout");
          localStorage.removeItem("bearer");
          localStorage.removeItem("user");
          this.router.navigateByUrl("/pages/login");
          // try {
          //   this.toasterService.error(err.error.message, err.error.title, {
          //     positionClass: "toast-bottom-center",
          //   });
          // } catch (e) {
          //   this.toasterService.error("An error occurred", "", {
          //     positionClass: "toast-bottom-center",
          //   });
          // }
          //log error
        }

        console.log("retur");
        return throwError(err);
      }),
    );
  }
}
