<div class="app-footer">
  <div class="app-footer__inner">
    <div class="app-footer-left d-none d-sm-block">
      &nbsp;&nbsp;&nbsp;&nbsp;
      <a *ngIf="needHelp" class="mailto mb-2 mr-2 btn-icon btn-pill btn btn-green" href="mailto:support@mybeacon.ca"
        ><i class="lnr-hand btn-icon-wrapper"> </i>Help / Support</a
      >
      <a *ngIf="needTerms" href="/pages/terms-condition" target="_blank"
        ><span class="text-green">Terms & Conditions</span></a
      >
      <a *ngIf="needPrivacyPolicy" href="/pages/privacy-policy" target="_blank"
        ><span class="text-green">Privacy Policy</span></a
      >
    </div>
    <div>
      @ Copyright MyBeacon Services Inc, {{ year }}.All Rights Reserved. For further details see our
      <a href="https://mybeacon.ca/privacy-policy/" target="_blank">Privacy Policy</a>
      and
      <a href="https://mybeacon.ca/terms-conditions/" target="_blank">Terms & Conditions</a>.
    </div>
  </div>
</div>
