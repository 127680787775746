<ng-template #confirmation let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ message }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="c(false)">Cancel</button>
    <button type="button" class="btn btn-success" (click)="c(true)">Confirm</button>
  </div>
</ng-template>
