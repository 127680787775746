import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { datadogRum } from "@datadog/browser-rum";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

datadogRum.init({
  applicationId: environment.datadog_key,
  clientToken: environment.datadog_client_token,
  site: "us5.datadoghq.com",
  service: "fairexui",
  env: environment.env,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.register('/ngsw-worker.js');
    // }
  })
  .catch((err) => console.error(err));
