<div [class]="'app-header header-shadow ' + (config$ | async).headerTheme">
  
  <div class="app-header__content">
    <div class="app-header-left p-0">
      <!-- <app-mega-menu></app-mega-menu> -->
      <!-- <h3 class="menu-header-title">Hello, dineshaaa221@gmail.com</h3> -->
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <h6 class="widget-heading m-0">Hello,{{ user.clientDet?.clientName }}</h6>
            <div class="widget-subheading opacity-8">
              {{ date | date: "MMMM dd, yyyy" }}
            </div>
          </div>
        </div>
      </div>
      <h4></h4>
    </div>
    <!--    <div class="app-header-right">-->
    <!--      <app-dots></app-dots>-->
    <!--      <app-user-box></app-user-box>-->
    <!--    </div>-->
  </div>
  <div class="app-header__mobile-menu">
    <button
      type="button"
      class="hamburger close-sidebar-btn hamburger--elastic"
      [ngClass]="{ 'is-active': globals.toggleSidebarMobile }"
      (click)="toggleSidebarMobile()"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
  </div>
</div>
