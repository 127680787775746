import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { masterURL } from "src/environments/masterURL";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { saveAs as importedSaveAs } from "file-saver";
@Injectable({
  providedIn: "root",
})
@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  constructor(private api: ApiService) {}

  addFile(file, docType, docRef, quoteId) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("docType", docType);
    formData.append("docRef", docRef);

    let url = environment.api.baseurl + environment.api.version + masterURL.api.addQuoteDoc;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);

    return this.api.post(url, formData);
  }

  addKYCFile(file, docType, docRef) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("docType", docType);
    formData.append("docRef", docRef);



    let url=environment.api.baseurl+environment.api.version+masterURL.api.addKYCDoc;
    url=url.replace(":AGENT",environment.agent);

    return this.api.post( url,formData);
  }
  
  getFile(docRefId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.getFile;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":docRefId", docRefId);
    window.location.href = url;
  }
}
