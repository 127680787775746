<div class="row m-1">
  <div class="col-12 col-md-6">
    <div class="label-value-container">
      <div class="text-label pl-0">Address</div>
      <div class="text-value">
        :<span>
          {{ address?.address }}<br />
          &nbsp;{{ address?.city }} {{ address?.city ? "-" : "" }} {{ address?.postalCode }}<br />
          &nbsp;{{ address?.state }} {{ address?.state ? "-" : "" }} {{ address?.country }}<br
        /></span>
      </div>
    </div>
  </div>
</div>
