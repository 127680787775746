<div
  [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme"
  (mouseover)="sidebarHoverMouseIn()"
  (mouseout)="sidebarHoverMouseOut()"
>
  <div class="app-header__logo">
    <div class="header__pane ml-auto pr-3">
      <button
        type="button"
        class="hamburger close-sidebar-btn hamburger--elastic"
        [ngClass]="{ 'is-active': globals.toggleSidebar }"
        (click)="toggleSidebar()"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>

    <div class="logo-src"></div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar
        [autoPropagation]="true"
        class="scroll-container"
        [config]="config"
        [scrollIndicators]="true"
        style="max-width: 500px; max-height: 420px"
      >
        <div class="v-sidebar-menu vsm-default m-2">
          <div class="vsm-list">
            <div class="vsm-item">
              <a
                routerLink="app/pages/customer/dashboard"
                routerLinkActive="active-item"
                [routerLinkActiveOptions]="{ exact: true }"
                class="vsm-link"
                ><i class="vsm-icon las la-home"></i>
                <span class="vsm-title">Dashboard</span>
              </a>
            </div>
            <div class="vsm-item">
              <a
                routerLink="app/pages/customer/transList"
                routerLinkActive="active-item"
                [routerLinkActiveOptions]="{ exact: true }"
                class="vsm-link"
                ><i class="vsm-icon las fa-credit-card"></i>
                <span class="vsm-title">My Remittances</span>
              </a>
            </div>
            <div class="vsm-item">
              <a
                routerLink="app/pages/customer/beneficiaries"
                routerLinkActive="active-item"
                [routerLinkActiveOptions]="{ exact: true }"
                class="vsm-link"
                ><i class="vsm-icon las fa-users"></i>
                <span class="vsm-title">Beneficiaries</span>
              </a>
            </div>
            <div class="vsm-item">
              <a
                routerLink="app/pages/customer/KYCList"
                routerLinkActive="active-item"
                [routerLinkActiveOptions]="{ exact: true }"
                class="vsm-link"
                ><i class="vsm-icon las fa-address-card"></i>
                <span class="vsm-title">KYC</span>
              </a>
            </div>
            <!-- <div class="vsm-item">
              <a
                routerLink="app/pages/customer/transaction"
                routerLinkActive="active-item"
                [routerLinkActiveOptions]="{ exact: true }"
                class="vsm-link"
              ><i class="vsm-icon las fa-bank"></i>
                <span class="vsm-title">Transactions</span>
              </a>
            </div> -->
          </div>
        </div>
      </perfect-scrollbar>

      <div class="d-block text-center card-footer helpbox" style="background: #fbf9f5">
        <!-- <a class="mailto mb-2 mr-2 btn-icon btn-pill btn btn-blue" href="mailto:support@mybeacon.ca">
          <i class="las pe-7s-headphones btn-icon-wrapper"> </i>Support
        </a> -->
      </div>
    </div>
  </div>
</div>
