import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class TransactionService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  redirect(quote) {
    console.log(" In Service router");

    let level = quote.lrsQuoteLevel;
    let status = quote.transStatus;
    let quoteId = quote.quoteProcessId;
    let url = "/app/pages/customer/payerInfo";
    if (level == 0) url = "/app/pages/customer/payerInfo";
    if (level == 1) url = "/app/pages/customer/docInfo";
    if (level == 2) url = "/app/pages/customer/beniInfo";
    if (level == 3) url = "/app/pages/customer/payerAccountInfo";
    if (level == 4) url = "/app/pages/customer/transReview";
    if (level == 5) url = "/app/pages/customer/transView";

    url = url + "?quoteId=" + quoteId;

    console.log("URL...." + url);

    //  {path:"transReview",component:TransactionPaymentConfirmationComponent},
    //  {path:"transSuccess",component:TransactionSuccessFailureComponent},
    //  {path:"remittanceView",component:TransactionViewComponent},

    this.router.navigateByUrl(url);
  }

  moveNext(quote, currentLevel) {
    console.log(" In Service router");

    let level = currentLevel;
    let status = quote.transStatus;
    let quoteId = quote.quoteProcessId;
    let url = "/app/pages/customer/payerInfo";
    if (level == 0) url = "/app/pages/customer/payerInfo";
    if (level == 1) url = "/app/pages/customer/docInfo";
    if (level == 2) url = "/app/pages/customer/beniInfo";
    if (level == 3) url = "/app/pages/customer/payerAccountInfo";
    if (level == 4) url = "/app/pages/customer/transReview";

    url = url + "?quoteId=" + quoteId;

    console.log("URL...." + url);

    //  {path:"transReview",component:TransactionPaymentConfirmationComponent},
    //  {path:"transSuccess",component:TransactionSuccessFailureComponent},
    //  {path:"remittanceView",component:TransactionViewComponent},

    this.router.navigateByUrl(url);
  }
  moveBack(quote, currentLevel) {
    console.log(" In Service router");

    let level = currentLevel;
    let status = quote.transStatus;
    let quoteId = quote.quoteProcessId;
    let url = "/app/pages/customer/payerInfo";
    if (level == 0) url = "/app/pages/customer/payerInfo";
    if (level == 1) url = "/app/pages/customer/payerInfo";
    if (level == 2) url = "/app/pages/customer/docInfo";
    if (level == 3) url = "/app/pages/customer/beniInfo";
    if (level == 4) url = "/app/pages/customer/payerAccountInfo";
    if (level == 5) url = "/app/pages/customer/transReview";

    url = url + "?quoteId=" + quoteId;

    console.log("URL...." + url);

    //  {path:"transReview",component:TransactionPaymentConfirmationComponent},
    //  {path:"transSuccess",component:TransactionSuccessFailureComponent},
    //  {path:"remittanceView",component:TransactionViewComponent},

    this.router.navigateByUrl(url);
  }
}
