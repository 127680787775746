import { Component, EventEmitter, Output } from "@angular/core";
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-date-range-picker",
  templateUrl: "./date-range-picker.component.html",
  styleUrls: ["./date-range-picker.component.sass"],
})
export class DateRangePickerComponent {
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  showDatepicker = false;

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
  ) {
    //this.fromDate = this.calendar.getToday();
    //this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
    this.showDatepicker = false;
  }

  @Output() dateRangeSelected = new EventEmitter<{
    fromDate: NgbDate;
    toDate: NgbDate;
  }>();

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.emitDateRange();
  }

  emitDateRange() {
    if (this.fromDate && this.toDate) {
      this.dateRangeSelected.emit({
        fromDate: this.fromDate,
        toDate: this.toDate,
      });
      const fromDateString = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
      const toDateString = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`;
      console.log(`Emitted date range: From ${fromDateString}, To ${toDateString}`);
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}
