import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-address-form",
  templateUrl: "./address-form.component.html",
  styleUrls: ["./address-form.component.sass"],
})
export class AddressFormComponent implements OnInit, OnChanges {
  @Input("addressForm") formControl;

  @Input() countries;

  @Input() title;
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes)
  }

  ngOnInit(): void {}
}
