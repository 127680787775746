import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class ApiService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}
  public isLoginError: boolean = false;
  private formatErrors(error: any) {
    if (error.status === 401) {
      window.location.href = "/";
    }
    if (error.status === 403 && !this.isLoginError) {
      alert("You have not enough permission for this operation");
      this.isLoginError = true;
    }
    return throwError(error.error);
  }

  get(url: string, params: HttpParams = new HttpParams()): Observable<any> {
    //console.log(url, params);
    return this.http.get(url, { params }).pipe(catchError(this.formatErrors));
  }

  post(url: string, body: object = {}): Observable<any> {
    //console.log(url)
    return this.http.post(url, body).pipe(catchError(this.formatErrors));
  }

  postWithoutBody(url: string): Observable<any> {
    //console.log(url)
    return this.http.post(url, null).pipe(catchError(this.formatErrors));
  }

  put(url: string, body: object = {}): Observable<any> {
    return this.http.put(url, JSON.stringify(body)).pipe(catchError(this.formatErrors));
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url).pipe(catchError(this.formatErrors));
  }

  downloadFile(
    url: string,

    params: HttpParams = new HttpParams(),
  ): Observable<any> {
    // //console.log(url, params);
    return this.http
      .get(url, {
        responseType: "blob",
        observe: "response",
      })
      .pipe(catchError(this.formatErrors));
  }

  postDownloadFile(url: string, body: object = {}): Observable<any> {
    console.log(url);
    return this.http
      .post(url, body, {
        responseType: "blob",
        observe: "response",
      })
      .pipe(catchError(this.formatErrors));
  }
}
