import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "Confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.sass"],
})
export class ConfirmationComponent implements OnInit {
  @Input("message") message;

  @Input("data") data;

  @ViewChild("confirmation") searchModal;

  @Output("onClose") onClose = new EventEmitter<any>();

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}
  closeResult: any;

  open() {
    this.modalService.open(this.searchModal).result.then(
      (result) => {
        //console.log(result)
        this.closeResult = `Closed with: ${result}`;
        this.onClose.emit(result);
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.onClose.emit(false);
      },
    );
  }
  getDismissReason(reason: any) {
    // throw new Error("Method not implemented.");
  }
}
