import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";

declare var window: any;

@Injectable({
  providedIn: "root",
})
export class FreshdeskWidgetService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initializeWidget() {
    window.fwSettings = {
      widget_id: 1070000001249,
    };
    if (typeof window.FreshworksWidget !== "function") {
      window.FreshworksWidget = function () {
        window.FreshworksWidget.q.push(arguments);
      };
      window.FreshworksWidget.q = [];
    }
  }

  showWidget() {
    window.FreshworksWidget("show");
  }

  hideWidget() {
    window.FreshworksWidget("hide");
  }

  handleResponsiveWidget() {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const widgetElement = document.querySelector(".freshwidget-iframe");

    if (widgetElement) {
      if (isMobile) {
        // For mobile devices, align bottom right
        this.renderer.setStyle(widgetElement, "bottom", "10px");
        this.renderer.setStyle(widgetElement, "right", "10px");
      } else {
        // For other devices, align bottom left
        this.renderer.setStyle(widgetElement, "bottom", "10px");
        this.renderer.setStyle(widgetElement, "left", "10px");
      }
    }
  }
}
