import { Component, HostBinding, OnInit } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { ThemeOptions } from "../../../theme-options";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  faEllipsisV = faEllipsisV;
  user: any;
  date = new Date();
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log("user.::" + this.user);
  }
  constructor(public globals: ThemeOptions) {}

  @HostBinding("class.isActive")
  get isActiveAsGetter() {
    return this.isActive;
  }

  isActive: boolean;

  @select("config") public config$: Observable<any>;

  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }

  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }
}
