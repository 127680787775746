import { Component, OnInit } from "@angular/core";
import { FreshdeskWidgetService } from "@app/shared/service/freshdesk-widget.service";

@Component({
  selector: "app-terms-condition",
  templateUrl: "./terms-condition.component.html",
})
export class TermsConditionComponent implements OnInit {
  constructor(private freshdeskWidgetService: FreshdeskWidgetService) {}

  ngOnInit(): void {
    this.freshdeskWidgetService.hideWidget();
  }

  ngOnDestroy(): void {
    this.freshdeskWidgetService.showWidget();
  }
}
