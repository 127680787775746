import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ModalHeaderComponent } from "./modal/modal-header/modal-header.component";
import { ModalBodyComponent } from "./modal/modal-body/modal-body.component";
import { ModalFooterComponent } from "./modal/modal-footer/modal-footer.component";
import { ConfirmationComponent } from "./confirmation/confirmation.component";
import { CardComponent } from "./custom/card/card.component";
import { CardBodyComponent } from "./custom/card-body/card-body.component";
import { CardHeaderComponent } from "./custom/card-header/card-header.component";
import { CardFooterComponent } from "./custom/card-footer/card-footer.component";
import { ModalComponent } from "./modal/modal.component";
import { NgbAlertModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoadingComponent } from "./loading/loading.component";
import { NgxLoadingModule } from "ngx-loading";
import { AddressDetailsViewComponent } from "./custom/address-details-view/address-details-view.component";
import { CustomSelectComponent } from "./custom-select/custom-select.component";
import { CustomInputComponent } from "./custom-input/custom-input.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AddressFormComponent } from "./address-form/address-form.component";
import { CurrencySymbolComponent } from "./currency-symbol/currency-symbol.component";
import { TableSortComponent } from "./table-sort/table-sort.component";
import { DateRangePickerComponent } from "./date-range-picker/date-range-picker.component";

@NgModule({
  declarations: [
    CardComponent,
    CardBodyComponent,
    CardHeaderComponent,
    CardFooterComponent,
    ModalComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    ConfirmationComponent,
    LoadingComponent,
    AddressDetailsViewComponent,
    CustomSelectComponent,
    CustomInputComponent,
    AddressFormComponent,
    CurrencySymbolComponent,
    TableSortComponent,
    DateRangePickerComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgxLoadingModule.forRoot({}),
    NgbAlertModule,
    NgSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CardComponent,
    CardBodyComponent,
    CardHeaderComponent,
    CardFooterComponent,
    ModalComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent,
    ConfirmationComponent,
    LoadingComponent,
    AddressDetailsViewComponent,
    CustomSelectComponent,
    CustomInputComponent,
    AddressFormComponent,
    CurrencySymbolComponent,
    DateRangePickerComponent,
  ],
})
export class ComponentsModule {}
