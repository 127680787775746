import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { Router } from "@angular/router";
import { MasterService } from "@app/shared/service/master.service";
import { ToastrService } from "ngx-toastr";
import { FileUploadService } from "@app/shared/service/file-upload.service";
import { TransactionService } from "@app/shared/service/transaction.service";
@Component({
  selector: "app-sso-router",
  templateUrl: "./sso-router.component.html",
  styleUrls: ["./sso-router.component.sass"],
})
export class SsoRouterComponent implements OnInit {
  loading: boolean = true;
  ssoToken = "";
  constructor(
    private MasterService: MasterService,
    private activatedRoute: ActivatedRoute,
    public toasterService: ToastrService,
    public fileUploadService: FileUploadService,
    private transactionService: TransactionService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      console.log("Path Param");
      console.log(params);
      this.ssoToken = params.ssoToken;
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      console.log("Query Param");
      console.log(params);
    });
    this.login();
  }

  login(): void {
    console.log("OTP submit");
    this.loading = true;
    this.MasterService.getSSOToken(this.ssoToken).subscribe(
      (res) => {
        this.loading = false;
        console.log("Login Validation Staart");
        localStorage.setItem("bearer", res.accessToken);
        this.MasterService.getUser().subscribe((res) => {
          console.log("userInfo" + JSON.stringify(res.lrsUser));
          localStorage.setItem("user", JSON.stringify(res.lrsUser));
          localStorage.setItem("name", JSON.stringify(res.userName));
          if (res) {
            let url = "customer/dashboard";
            this.loading = false;
            this.router.navigateByUrl("app/pages/" + url);
          }
        });

        console.log("Login Validation end");
        //this.router.navigateByUrl("");
      },
      (err) => {
        console.log("Some error occured" + JSON.stringify(err));
        this.toasterService.error(this.MasterService.getError(err), "Error", {
          positionClass: "toast-top-right",
        });
        this.loading = false;
      },
    );
  }
}
