// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const masterURL = {
  api: {
    addQuoteDoc: "/doc/:AGENT/quoteDoc/:quoteId",
    addKYCDoc: "/doc/:AGENT/kyc/",
    getFile: "/doc/:AGENT/download/:docRefId",
    getUser: "/dashboard/:AGENT/userDet",
    getDashBoardData: "/dashboard/:AGENT/list",
    auth: {
      loginOtp: "/open/:AGENT/generateOTP",
      getToken: "/open/:AGENT/getToken",
      signupOtp: "/open/:AGENT/generateSignUpOTP",
      saveSignup: "/open/:AGENT/saveSignUp",
      ssoToken: "/open/:AGENT/getSSOToken/:token",
      getSSOData: "/open/:AGENT/getSSOData/:UUID",
      saveSSOData: "/open/:AGENT/ssoAuth/:UUID",
      ssoSignUpOTP: "/open/:AGENT/generateSSOSignUpOTP",
    },
    open: {
      getQuote: "/open/:AGENT/generateQuote",
    },

    kyc: {
      allKyc: "/kyc/:AGENT/allKYC",
      allKYCActive: "/kyc/:AGENT/allKYCActive",
      kycPreLoad: "/kyc/:AGENT/addPreLoad",
      validatePan: "/kyc/:AGENT/validatePan",
      aadhaarOtp: "/kyc/:AGENT/aadhaarOtp",
      resentAadhaarOtp: "/kyc/:AGENT/resentAadhaarOtp/:kycId",
      submitAadhaarOtp: "/kyc/:AGENT/submitAadhaarOtp",
      saveKYC: "/kyc/:AGENT/saveKYC",
      getKyc: "/kyc/:AGENT/getKyc/:kycId",
      activeInActive: "/kyc/:AGENT/activeInActive/:kycId/:activateStatus",
      passportData:"/kyc/:AGENT/passportValidate",
    },
    beni: {
      list: "/beni/:AGENT/list",
      preLoad: "/beni/:AGENT/preLoad",
      currencyValidate: "/beni/:AGENT/currencyValidate/:curId",
      get: "/beni/:AGENT/get/:beniId",
      save: "/beni/:AGENT/save",
      update: "/beni/:AGENT/update/:beniId",
      getAll: "/beni/:AGENT/getAll/:curId",
    },
    bankAccount: {
      allBankAccount: "/bankAcc/:AGENT/allBankAccount",
      addPreLoad: "/bankAcc/:AGENT/addPreLoad",
      saveBankAccount: "/bankAcc/:AGENT/saveBankAccount",
      activeInActive: "/bankAcc/:AGENT/activeInActive/:accountId/:activateStatus",
    },

    quote: {
      list: "/quote/:AGENT/list",
      generateNewQuote: "/quote/:AGENT/generateNewQuote",
      updateQuote: "/quote/:AGENT/update/:quoteId",
      get: "/quote/:AGENT/get/:quoteId",
      getDetailReview: "/quote/:AGENT/getDetailReview/:quoteId",
      kycPurposeUpdate: "/quote/:AGENT/kycPurposeUpdate/:quoteId",
      docUpdate: "/quote/:AGENT/docUpdate/:quoteId",
      beniUpdate: "/quote/:AGENT/beniUpdate/:quoteId",
      bankUpdate: "/quote/:AGENT/bankUpdate/:quoteId",
      getPaymentStatus: "/quote/:AGENT/paymentStatus/:paymentRefId",
      getFinalView: "/quote/:AGENT/getFinalView/:quoteId",
      getStatusViewDoc: "/quote/:AGENT/getStatusViewDoc/:quoteId",
      reInitiate: "/quote/:AGENT/reInitiate/:quoteId",
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
