import { Component, EventEmitter, Input, Output } from "@angular/core";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-page-title",
  templateUrl: "./page-title.component.html",
})
export class PageTitleComponent {
  faStar = faStar;
  faPlus = faPlus;

  @Input() heading;
  @Input() subheading;
  @Input() icon;
  @Input() addButtonTitle;
  @Input() enableAddButton = false;
  @Output() add = new EventEmitter<any>();

  @Input() addButtonTitle1;
  @Input() enableAddButton1 = false;
  @Output() add1 = new EventEmitter<any>();

  @Input() addButtonTitle2;
  @Input() enableAddButton2 = false;
  @Output() add2 = new EventEmitter<any>();

  @Input() filter = false;
  @Output() filterAction = new EventEmitter<any>();

  @Input() needDownLoad = false;
  @Output() downLoadAction = new EventEmitter<any>();

  @Input() setting = false;
  @Output() settingAction = new EventEmitter<any>();
  addClick() {
    this.add.emit();
  }

  addClick1() {
    this.add1.emit();
  }

  addClick2() {
    this.add2.emit();
  }

  filterActionClick() {
    this.filterAction.emit();
  }

  downLoadActionClick() {
    this.downLoadAction.emit();
  }

  settingActionClick() {
    this.settingAction.emit();
  }
}
