import {
  Component,
  Input,
  OnInit,
  OnChanges,
  OnDestroy,
  SkipSelf,
  Host,
  Optional,
  ViewChild,
  Renderer2,
  forwardRef,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NgControl,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
} from "@angular/forms";
import { formatNumber } from "@angular/common";

@Component({
  selector: "app-custom-input",
  templateUrl: "./custom-input.component.html",
  styleUrls: ["./custom-input.component.sass"],
})
export class CustomInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() name;
  @Input() id;
  @Input() placeholder;
  @Input() label;
  // @Input() value;
  @Input() formControl;
  @Input() disabled: boolean;
  @Input() roundingDecimalPlaces = 2;
  @Input() forceDisableRounding = false;

  @Output() onBlur;

  value: any = "";
  @ViewChild("myDiv") myDiv: ElementRef;
  @ViewChild("textarea") textarea;
  otherErrors: boolean = true;
  NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;

  constructor(
    public renderer: Renderer2,
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
    private ngControl?: NgControl,
  ) {
    this.onBlur = new EventEmitter<any>();
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {}

  ngOnDestroy() {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  propagateChange: any = () => {};

  onChange: any = () => {};

  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    this.propagateChange = fn;
  }

  valueChanged(value) {
    try {
      this.onChange(value);
      this.onTouched(value);
      this.propagateChange(value);
    } catch (e) {
      //  console.log(e);
    }
  }

  change($event) {
    // console.log('Changed');
    this.otherErrors = false;
    this.myDiv.nativeElement.classList.remove("form-control-error");
    const value = $event.target.value;
    this.valueChanged(value);
  }

  blur($event) {
    // console.log('Changed', $event.target.value);
    this.onBlur.emit($event);
  }

  touched($event) {
    this.valueChanged($event);
  }

  hasError(value) {
    if (this.formControl.hasError(value)) {
      this.otherErrors = true;
      this.myDiv.nativeElement.classList.add("form-control-error");
      return this.otherErrors;
    }
    this.otherErrors = false;
    return this.otherErrors;
  }

  isRequired() {
    if (this.formControl && this.formControl.validators) {
      const validators = this.formControl.validator({} as AbstractControl);
      if (validators && validators.required) {
        return true;
      }
    }
    return false;
  }

  isEmail() {
    const validators = this.formControl.validator({} as AbstractControl);
    if (validators && validators.emailValidator) {
      return true;
    }
    return false;
  }
}
