import { Component, OnInit } from "@angular/core";
import { FreshdeskWidgetService } from "@app/shared/service/freshdesk-widget.service";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.sass"],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private freshdeskWidgetService: FreshdeskWidgetService) {}

  ngOnInit(): void {
    this.freshdeskWidgetService.hideWidget();
  }

  ngOnDestroy(): void {
    this.freshdeskWidgetService.showWidget();
  }
}
