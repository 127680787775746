// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  env: "development",
  datadog_key: "56700e67-e99b-4dd4-95bd-fda5938ef33b",
  datadog_client_token: "pub5ddbb661980b79d6497be78f42530f01",
  agent: "MYBEACON",
  intercom: {
    appId: "xz0rh86x",
  },
  api: {
    //baseurl: "http://localhost:5000/",
    version: "/api/v2",
    baseurl: "https://sandbox.fairexpay.com",
    // baseurl: "http://124.123.66.250:8081",
  },
};
