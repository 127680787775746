<form *ngIf="formControl" class="mb-2 mt-0" [formGroup]="formControl" #form>
  <label class="register-label-title text-primary">
    <span *ngIf="title; else default">
      {{ title }}
    </span>
    <ng-template #default>Address</ng-template>
  </label>
  <div class="register-group p-2 m-0 row">
    <div class="col-md-12">
      <fieldset class="form-group">
        <div tabindex="-1" role="group">
          <label for="examplePassword"> Address</label>
          <textarea
            id="address"
            name="address"
            rows="4"
            formControlName="address"
            placeholder="Address here..."
            class="form-control"
          ></textarea>
        </div>
        <div
          *ngIf="formControl.get('address').touched && formControl.get('address').errors?.required"
          class="form-control-error errors m-1"
        >
          please enter the Address..
        </div>
      </fieldset>
    </div>
    <div class="col-md-6">
      <fieldset class="form-group">
        <div tabindex="-1" role="group">
          <label for="acctNo"> City</label
          ><input
            id="city"
            name="city"
            type="text"
            placeholder="city here"
            formControlName="city"
            class="form-control"
          />
        </div>
        <div
          *ngIf="formControl.get('city').touched && formControl.get('city').errors?.required"
          class="form-control-error errors m-1"
        >
          please enter the city..
        </div>
      </fieldset>
    </div>

    <div class="col-md-6">
      <fieldset class="form-group">
        <div tabindex="-1" role="group">
          <label for="examplePassword"> State</label
          ><input
            id="state"
            name="state"
            placeholder="State here"
            type="text"
            formControlName="state"
            class="form-control"
          />
        </div>
        <div
          *ngIf="formControl.get('state').touched && formControl.get('state').errors?.required"
          class="form-control-error errors m-1"
        >
          please enter the state..
        </div>
      </fieldset>
    </div>
    <div class="col-md-6">
      <fieldset class="form-group">
        <div tabindex="-1" role="group">
          <label for="pincode"> Pincode</label
          ><input
            id="pincode"
            name="pincode"
            placeholder="Pincode here"
            type="text"
            formControlName="pincode"
            class="form-control"
          />
        </div>
        <div
          *ngIf="formControl.get('pincode').touched && formControl.get('pincode').errors?.required"
          class="form-control-error errors m-1"
        >
          please enter the pincode..
        </div>
      </fieldset>
    </div>
    <div class="col-md-6">
      <fieldset class="form-group">
        <div tabindex="-1" role="group">
          <label for="country">Country<span class="required">*</span></label>
          <ng-select
            [items]="countries"
            bindLabel="VALUE"
            bindValue="VALUE"
            formControlName="country"
            [placeholder]="'Select Country'"
          >
          </ng-select>
        </div>
        <div
          *ngIf="formControl.get('country').touched && formControl.get('country').errors?.required"
          class="form-control-error errors m-1"
        >
          please select the country..
        </div>
      </fieldset>
    </div>
  </div>
</form>
