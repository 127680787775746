import { Component, OnInit } from "@angular/core";

@Component({
  selector: "CardHeader",
  templateUrl: "./card-header.component.html",
  styleUrls: ["./card-header.component.sass"],
})
export class CardHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
