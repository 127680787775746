import { Component, OnInit } from "@angular/core";

@Component({
  selector: "CardFooter",
  templateUrl: "./card-footer.component.html",
  styleUrls: ["./card-footer.component.sass"],
})
export class CardFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
