<ngx-loading
  show="true"
  [config]="{ animationType: ngxLoadingAnimationTypes.pulse }"
  [template]="loadingTemplate"
  style="position:fixed;    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;"
></ngx-loading>
