import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MasterService } from "@app/shared/service/master.service";
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FreshdeskWidgetService } from "@app/shared/service/freshdesk-widget.service";
import { FeeComparisonModalComponent } from "../fee-comparison-modal/fee-comparison-modal.component";
import { environment } from "../../../../environments/environment";
@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.sass"],
})
export class LandingPageComponent implements OnInit {
  year: string;
  quoteForm: FormGroup;
  quote: any;
  environment = environment.env === "development" ? "Montreal" : "Banff";
  toCur: any;
  currencyList: any[] = [];
  saving = 0;
  bankExRate = 0;
  calculatorDefaultLoad = false;
  constructor(
    private MasterService: MasterService,
    private router: Router,
    public toasterService: ToastrService,
    private freshdeskWidgetService: FreshdeskWidgetService,
  ) {
    let date = new Date();
    this.year = date.getFullYear().toString();
  }

  ngOnInit(): void {
    this.getQuote();
    this.freshdeskWidgetService.hideWidget();
  }

  ngOnDestroy() {
    this.freshdeskWidgetService.showWidget();
  }

  loading: boolean = false;
  getQuote() {
    if (!this.quoteForm) this.intializeForm();
    if (this.quoteForm.valid) {
      this.loading = true;
      let temp: any = this.quoteForm.getRawValue();
      console.log("temp" + JSON.stringify(temp));

      this.MasterService.getDirectQuote(temp).subscribe(
        (res: any) => {
          this.loading = false;
          console.log(res);
          this.quote = res.trans;
          this.currencyList = res.curList;
          if (!temp.toCurId) temp.toCurId = res.defaultCur;
          this.toCur = this.currencyList.filter((x) => x.currencyid == temp.toCurId)[0];
          this.saving = res.saving + 800;
          // this.saving = ((res.trans.calToAmount * this.bankExRate) + 1500) - res.trans.finalFromAmount;
          this.bankExRate = res.bankRate;
          if (!this.calculatorDefaultLoad) {
            this.quoteForm.get("toCurId").setValue(res.defaultCur);
            this.quoteForm.get("toCurId").setValidators([Validators.required]);
            this.quoteForm.get("toCurId").updateValueAndValidity();
            this.calculatorDefaultLoad = true;
          }
        },
        (err) => {
          this.loading = false;
          this.toasterService.error(this.MasterService.getError(err), "Error", { positionClass: "toast-top-right" });
        },
      );
    } else {
      this.quoteForm.markAllAsTouched();
    }
  }

  intializeForm() {
    console.log("intialize");
    console.log("quoteForm", this.quoteForm);
    this.quoteForm = new FormGroup({
      toCurId: new FormControl(null),
      amount: new FormControl(1000, [Validators.required, Validators.min(Number.MIN_VALUE)]),
    });
  }

  login() {
    this.router.navigateByUrl("/pages/login");
  }

  signUp() {
    let temp: any = this.quoteForm.getRawValue();
    localStorage.removeItem("quoteReq");
    localStorage.setItem("quoteReq", JSON.stringify(temp));
    this.router.navigateByUrl("/pages/signUpLRS");
  }

  @ViewChild("feeComparisonModal") feeComparisonModal: FeeComparisonModalComponent;
  openComparison() {
    const { calToAmount, reverseExchangeRate, calFromAmount, addAmount, bankFinalAmount, totSavings } = this.quote;
    this.feeComparisonModal.recipientGets = calToAmount;
    this.feeComparisonModal.exchangeRate = reverseExchangeRate;
    this.feeComparisonModal.handlingFee = addAmount;
    this.feeComparisonModal.youSend = calFromAmount + 1200;
    this.feeComparisonModal.bankRate = this.bankExRate;
    this.feeComparisonModal.bankFinalAmount = calToAmount * this.bankExRate + 2000;
    // this.feeComparisonModal.totSavings= ((calToAmount * this.bankExRate) + 1500) - finalFromAmount;
    this.feeComparisonModal.totSavings = this.saving;
    this.feeComparisonModal.showModal();
  }
}
