import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
})
export class FooterComponent implements OnInit {
  year: string;
  @Input()
  public needHelp: boolean = false;
  @Input()
  public needTerms: boolean = false;
  @Input()
  public needPrivacyPolicy: boolean = false;

  constructor() {
    let date = new Date();
    this.year = date.getFullYear().toString();
  }

  ngOnInit() {}
}
