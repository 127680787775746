<div class="row no-gutters">
  <div class="d-flex bg-gray align-items-center col-md-12 col-lg-7">
    <!-- Company Logo -->
    <div class="col-lg-12 m-2 content-xs-alignment h-xs-100">
      <div class="text-left mt-2">
        <img src="/assets/images/mybeacon.png" alt="Company Logo" class="img-fluid" style="max-width: 200px" />
      </div>

      <div class="text-left mt-3">
        <h3 class="f-500 text-dark mb-3 mt-3">Sign up</h3>
        <form *ngIf="signupForm" [formGroup]="signupForm" #form>
          <!-- <h5 class="mb-4 menu-header-subtitle text-blue">Enter your details to continue</h5> -->
          <div class="mb-3">
            <label for="email" class="form-label sm-label mb-0">Email Id</label>
            <div class="col-12 col-md-7 p-0 mb-2 mb-md-0">
              <input
                formControlName="email"
                type="email"
                class="form-control styled-inputBox mr-2"
                id="email"
                placeholder="xyz@gmail.com"
              />
              <div
                *ngIf="signupForm.get('email')?.touched && signupForm.get('email')?.errors"
                class="form-control-error errors m-1"
              >
                Please enter valid email..
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label for="mobileNumber" class="form-label sm-label mb-0">Mobile Number</label>
            <div class="d-flex flex-column flex-md-row align-items-md-center">
              <div class="col-md-7 p-0 mb-2 mb-md-0">
                <div class="mb-3">
            <!-- <label for="mobileNumber" class="form-label sm-label mb-0" style="font-size: 12px; color: #636763">Mobile Number</label> -->
            
            <div class="d-flex flex-column flex-md-row align-items-md-center">
              <div class="col-md-7 p-0 mb-2 mb-md-0">
                <!-- ----------INICIO---------- -->
                 <div class="input-group d-flex justify-content-center align-items-center dashboardData" style="">
            <ng-select
              class="pt-2 p-xs-none landing custom mt-1 flex-grow-1"
              [(ngModel)]="selectedCountryCode"
              [ngModelOptions]="{standalone: true}"
              (change)="onCountryChange()"
              style="
                background: #e0e2df!important;
                padding: 0px !important;
                margin: 0px !important;
                width: 10px !important;
                border-radius: 12px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border: none;
              "
            >
            <!-- (change)="getQuote()" -->
              <ng-container *ngFor="let country of countryList">
                <ng-option [value]="country.countryCode">
                  
                  <span style="font-weight: 400; font-size: 16px;" class="pl-2">{{ country.countryCode }}: +{{ country.callingCode }}</span>
                </ng-option>
              </ng-container>
            </ng-select>

            <input
              type="tel"
              class="form-control form-control-lg flex-grow-1 text-right"
              style="background: #e0e2df; border-top-right-radius: 12px; border-bottom-right-radius: 12px; border: none; height: 52px; font-size: 16px;"
              formControlName="phoneNo"

                  minlength="10"
                  maxlength="15"
            />
          </div>
                 <!-- ---------FIN--------- -->
                <!-- <span
                  *ngIf="sentOtp"
                  id="timer"
                  class="timer f-500"
                  style="position: absolute; cursor: pointer; top: 50%; transform: translateY(-50%); right: 10px"
                  (click)="editNo()"
                  ><i class="fas fa fa-pencil text-darkBlue"></i
                ></span> -->
                <!-- <lable *ngIf="!noEdit" class="form-control styled-inputBox mr-2">{{ phoneNo }}</lable> -->
              </div>
            </div>
            <div *ngIf="phoneNoError" class="form-control-error errors m-1">
              Please enter valid phone no with countrycode
            </div>
          </div>
                <!-- <input
                  type="tel"
                  formControlName="phoneNo"
                  class="form-control styled-inputBox mr-2"
                  (focus)="checkDefault()"
                  placeholder="+91 xxxxxxxxxx"
                  minlength="13"
                  maxlength="15"
                /> -->
                <span
                  *ngIf="sentOtp"
                  id="timer"
                  class="timer f-500"
                  style="position: absolute; cursor: pointer; top: 50%; transform: translateY(-50%); right: 10px"
                  (click)="editNo()"
                  ><i class="fas fa fa-pencil text-darkBlue"></i
                ></span>
              </div>
              <div class="col-md-3 p-0 p-md-3 m-0 ml-md-0 mt-3 mt-md-0">
                <button
                  *ngIf="noEdit"
                  class="btn btn-mod btn-blue fw-normal w-100 w-sm-auto"
                  type="button"
                  (click)="sendOtp()"
                >
                  Send OTP
                </button>
                <!-- <button *ngIf="sentOtp" class="btn btn-mod btn-blue fw-normal w-100" type="button" (click)="editNo()">Edit</button> -->
                <button *ngIf="resentOtp" class="btn btn-mod btn-blue fw-normal" type="button" (click)="sendOtp()">
                  Resend OTP
                </button>
              </div>
            </div>

            <div
              *ngIf="signupForm.get('phoneNo')?.touched && signupForm.get('phoneNo')?.errors"
              class="form-control-error errors m-1"
            >
              Please enter valid phone no with countrycode
            </div>
          </div>

          <div class="mb-3" *ngIf="sentOtp">
            <label for="OTP" class="form-label sm-label mb-0">OTP</label>
            <div class="col-md-7 p-0 position-relative">
              <input formControlName="otp" type="text" class="form-control styled-inputBox mr-2" placeholder="******" />
              <span id="timer" class="timer f-500" style="position: absolute; transform: translateY(-165%); right: 10px"
                >00:{{ timer }}</span
              >
              <div
                *ngIf="signupForm.get('otp')?.touched && signupForm.get('otp')?.errors"
                class="form-control-error errors m-1"
              >
                Please enter valid OTP
              </div>
            </div>
          </div>

          <div class="mb-3 text-center">
            <div class="col-md-7 p-0 d-sm-flex align-items-center">
              <button
                *ngIf="sentOtp"
                class="btn btn-blue fw-normal mr-2 w-xs-100 mb-2"
                type="button"
                (click)="signup()"
              >
                Sign Up
              </button>
              <h6
                *ngIf="sentOtp"
                class="text-darkBlue f-500 f-500 mb-0 ml-auto"
                style="font-size: 15px; cursor: pointer"
                (click)="login()"
              >
                Existing User? <span class="font-weight-bold">Log In</span>
              </h6>
              <div>
                <h6
                  *ngIf="!sentOtp"
                  class="text-darkBlue pt-2 f-500 mb-0"
                  style="font-size: 15px; cursor: pointer"
                  (click)="login()"
                >
                  Existing User? <span class="font-weight-bold">Log In</span>
                </h6>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="w-100 mt-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-5 p-0 text-center text-sm-left">
              <a href="/pages/privacy-policy" target="_blank" class="f-500" style="cursor: pointer">Privacy Policy</a>
            </div>
            <div class="col-lg-7 p-0">
              <p class="text-center text-sm-left">&copy; Copyright MyBeacon Services Inc, {{ year }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-none d-lg-block col-lg-5">
    <div class="slider-light">
      <ngx-slick-carousel class="slick-slider slick-dotted" #slickModal="slick-carousel" [config]="slideConfig2">
        <div ngxSlickItem>
          <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-white">
            <div class="slide-img-bg"></div>
            <div class="slider-content text-dark">
              <div class="mb-2 text-center d-inline">
                <img src="assets/images/Slider1.png" width="400px" />
              </div>
              <h3>Explore the world with MyBeacon</h3>
            </div>
          </div>
        </div>
        <div ngxSlickItem>
          <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-white">
            <div class="slide-img-bg"></div>
            <div class="slider-content text-dark">
              <div class="mb-2 text-center d-inline">
                <img src="assets/images/Slider2.png" width="400px" />
              </div>
              <h3>Remit money without borders</h3>
            </div>
          </div>
        </div>
        <div ngxSlickItem>
          <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-white">
            <div class="slide-img-bg opacity-6"></div>
            <div class="slider-content text-dark">
              <div class="mb-2 text-center d-inline">
                <img src="assets/images/Slider3.png" width="400px" />
              </div>
              <h3>Securely pay your university fees using MyBeacon.</h3>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
