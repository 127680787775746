import { Component, Input, OnChanges, OnInit } from "@angular/core";
@Component({
  selector: "app-table-sort",
  templateUrl: "./table-sort.component.html",
  styleUrls: ["./table-sort.component.sass"],
})
export class TableSortComponent implements OnInit {
  @Input() columnName;
  @Input() title;

  columnDet: any = {};

  constructor() {}

  ngOnInit(): void {
    console.log("sssssssssssss");
  }
}
