import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";
import { environment } from "src/environments/environment";
import { masterURL } from "src/environments/masterURL";

@Injectable({
  providedIn: "root",
})
export class MasterService {
  constructor(private api: ApiService) {}

  getUser() {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.getUser;
    url = url.replace(":AGENT", environment.agent);
    return this.api.get(url);
  }
  loginOtp(loginData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.auth.loginOtp;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, loginData);
  }

  getToken(loginData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.auth.getToken;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, loginData);
  }

  getSSOToken(token) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.auth.ssoToken;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":token", token);
    return this.api.get(url);
  }

  getSSODataToken(UUID) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.auth.getSSOData;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":UUID", UUID);
    return this.api.get(url);
  }
  ssoSignUpOTP(loginData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.auth.ssoSignUpOTP;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, loginData);
  }

  saveSSOData(UUID, data) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.auth.saveSSOData;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":UUID", UUID);
    return this.api.post(url, data);
  }

  signupOtp(loginData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.auth.signupOtp;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, loginData);
  }

  saveSignup(signupData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.auth.saveSignup;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, signupData);
  }

  getDirectQuote(quoteData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.open.getQuote;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, quoteData);
  }

  getDashBoardData() {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.getDashBoardData;
    url = url.replace(":AGENT", environment.agent);
    return this.api.get(url);
  }

  allKyc() {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.kyc.allKyc;
    url = url.replace(":AGENT", environment.agent);
    return this.api.get(url);
  }
  allKYCActive() {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.kyc.allKYCActive;
    url = url.replace(":AGENT", environment.agent);
    return this.api.get(url);
  }
  kycPreLoad() {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.kyc.kycPreLoad;
    url = url.replace(":AGENT", environment.agent);
    return this.api.get(url);
  }
  getKyc(kycId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.kyc.getKyc;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":kycId", kycId);
    return this.api.get(url);
  }
  getbeni(beniId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.beni.get;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":beniId", beniId);
    return this.api.get(url);
  }

  validatePan(panData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.kyc.validatePan;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, panData);
  }
  aadhaarOtp(data) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.kyc.aadhaarOtp;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, data);
  }
  resentAadhaarOtp(kycId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.kyc.resentAadhaarOtp;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":kycId", kycId);
    return this.api.get(url);
  }
  submitAadhaarOtp(data) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.kyc.submitAadhaarOtp;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, data);
  }

  saveKYC(data) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.kyc.saveKYC;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, data);
  }
  activeInActive(kycId, activateStatus) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.kyc.activeInActive;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":kycId", kycId);
    url = url.replace(":activateStatus", activateStatus);
    return this.api.get(url);
  }

  passportData(data) {
    let url=environment.api.baseurl+environment.api.version+masterURL.api.kyc.passportData;
    url=url.replace(":AGENT",environment.agent);
    return this.api.post(url,data);
  }

  beniList(filter) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.beni.list;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, filter);
  }

  beniPreLoad() {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.beni.preLoad;
    url = url.replace(":AGENT", environment.agent);
    return this.api.get(url);
  }

  beniCurrencyValidate(curId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.beni.currencyValidate;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":curId", curId);
    return this.api.get(url);
  }

  beniget(beniId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.beni.get;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":beniId", beniId);
    return this.api.get(url);
  }

  beniSave(beniData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.beni.save;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, beniData);
  }

  beniUpdate(beniData, beniId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.beni.update;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":beniId", beniId);
    return this.api.post(url, beniData);
  }

  beniGetAll(curId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.beni.getAll;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":curId", curId);
    return this.api.get(url);
  }

  allBankAccount(userKycId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.bankAccount.allBankAccount;
    url = url.replace(":AGENT", environment.agent);
    url=url+"?kycId="+userKycId;
    return this.api.get(url);
  }
  bankAddPreLoad(userKycId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.bankAccount.addPreLoad;
    url = url.replace(":AGENT", environment.agent);
    url=url+"?kycId="+userKycId;
    return this.api.get(url);
  }
  bankActiveInActive(accountId, activateStatus) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.bankAccount.activeInActive;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":accountId", accountId);
    url = url.replace(":activateStatus", activateStatus);
    return this.api.get(url);
  }

  saveBankAccount(bankData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.bankAccount.saveBankAccount;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, bankData);
  }

  generateNewQuote(quoteData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.generateNewQuote;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, quoteData);
  }

  updateQuote(quoteData, quoteId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.updateQuote;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.post(url, quoteData);
  }

  quoteList(filter) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.list;
    url = url.replace(":AGENT", environment.agent);
    return this.api.post(url, filter);
  }

  getQuote(quoteId, updateRate) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.get;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    url = url + "?updateRate=" + updateRate;
    return this.api.get(url);
  }

  getDetailReview(quoteId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.getDetailReview;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.get(url);
  }

  getKycPurposeUpdate(quoteId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.kycPurposeUpdate;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.get(url);
  }

  getDocUpdate(quoteId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.docUpdate;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.get(url);
  }

  getQuoteBeniUpdate(quoteId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.beniUpdate;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.get(url);
  }

  getBankUpdate(quoteId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.bankUpdate;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.get(url);
  }

  kycPurposeUpdate(quoteId, reqData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.kycPurposeUpdate;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.post(url, reqData);
  }

  docUpdate(quoteId, reqData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.docUpdate;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.post(url, reqData);
  }

  quoteBeniUpdate(quoteId, reqData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.beniUpdate;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.post(url, reqData);
  }

  bankUpdate(quoteId, reqData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.bankUpdate;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.post(url, reqData);
  }

  getPaymentStatus(paymentRefId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.getPaymentStatus;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":paymentRefId", paymentRefId);
    return this.api.get(url);
  }

  getQuoteFinalView(quoteId) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.getFinalView;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.get(url);
  }

  getStatusViewDoc(quoteId, reqData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.getStatusViewDoc;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.post(url, reqData);
  }

  reInitiate(quoteId, reqData) {
    let url = environment.api.baseurl + environment.api.version + masterURL.api.quote.reInitiate;
    url = url.replace(":AGENT", environment.agent);
    url = url.replace(":quoteId", quoteId);
    return this.api.post(url, reqData);
  }

  getIFSCCodeDetails(ifscCode) {
    let url = "https://ifsc.razorpay.com/" + ifscCode;
    return this.api.get(url);
  }

  getError(err) {
    let msg;
    if (err) {
      if (err.message) {
        msg = err.message;
        if (msg.startsWith("[")) {
          msg = msg.replace("[", "");
          msg = msg.replace("]", "");
          // let msgs=JSON.parse(msg);
          // msg="<li>"+msgs.join('<li>');
          //arrValue.join(',')
        }
      }
    }
    if (!msg) msg = "Some error occured";
    return msg;
  }
}
