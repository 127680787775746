<!-- NAVBAR SECTION STARTS -->
<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container">
    <!-- Logo -->

    <!-- Login Button -->
    <button
      class="btn btn-mod btn-blue text-white fw-normal navbar-toggler"
      type="button"
      (click)="login()"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      Login
    </button>

    <!-- Navbar links -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <button
            class="btn btn-mod text-white btn-blue fw-normal"
            type="button"
            (click)="login()"
            style="width: 150px; font-size: 18px"
          >
            Login
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- NAVBAR SECTION ENDS -->

<div class="container mb-3 page-align">
  <div class="row mt-md-5 mt-0 d-flex align-items-center justify-content-center">
    <!-- Globe Section -->
    <div class="col-lg-8 col-md-12 mt-3 mt-lg-0 d-flex align-items-center justify-content-center">
      <div>
        <p class="mb-3 mb-sm-3 content">Save on College Fees with Beacon Remit</p>
        <h6 class="text-center text-sm-left p-1 mt-4" style="font-family: 'SF-Pro'!important">
          Cheapest FX Rate • RBI approved • Deposit money in any Canadian Bank
        </h6>
      </div>
    </div>

    <!-- Quote Calculator Section -->
    <div class="col-lg-4 col-md-12 mt-2 mt-lg-0 card p-3">
      <div class="QuoteContainer p-3">
        <!-- Quote Calculator -->
        <form class="justify-content-center" *ngIf="quoteForm" [formGroup]="quoteForm" #form>
          <!-- <div class="mt-2 d-flex justify-content-center">
                        <p class="text-center f-500" style="font-size: 2rem;">Get Quote</p>
                    </div> -->

          <!-- <div class="mb-3 text-left justify-content-center d-flex align-items-center">


                    </div> -->
          <label for="receiverCurrency" class="form-floating" style="font-size: 16px !important"
                >Receiver Currency</label
              >
          <div class="input-group d-flex justify-content-center align-items-center dashboardData" style="">
            <ng-select
              class="pt-2 p-xs-none landing custom mt-1 flex-grow-1"
              style="
                background: #f0f0f0!important;
                padding: 0px !important;
                margin: 0px !important;
                width: 10px !important;
                border-radius: 12px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border: none;
              "
              formControlName="toCurId"
              (change)="getQuote()"
            >
              <ng-container *ngFor="let currency of currencyList">
                <ng-option [value]="currency.currencyid">
                  <img
                    src="assets/flags/{{ currency.currency_code | lowercase }}.svg"
                    [alt]="currency.currency_name"
                    class="flag-icon"
                  />
                  <span style="font-weight: 600" class="pl-2 fs14">{{ currency.currency_code }}</span>
                </ng-option>
              </ng-container>
            </ng-select>
            <div
              *ngIf="quoteForm.get('toCurId')?.touched && quoteForm.get('toCurId')?.errors"
              class="form-control-error errors m-1"
            >
              Please select the currency
            </div>

            <input
              type="number"
              class="form-control form-control-lg flex-grow-1 text-right f-700"
              style="background: #f0f0f0; border-top-right-radius: 12px; border-bottom-right-radius: 12px; border: none; height: 52px; color: #081517; "
              placeholder=" "
              formControlName="toCurId"
              aria-label="Receiver Currency"
              placeholder="Enter Amount"
              formControlName="amount"
              (change)="getQuote()"
            />
          </div>

          <!-- <div class="mb-3 text-left justify-content-center d-flex align-items-center">
                        <div>
                            <label for="youSend" class="form-label sm-label">Recipient Amount</label>
                            <div>
                                <input type="number" class="form-select styled-input font-weight-bold form-control"  id="amount" placeholder="Enter Amount" formControlName="amount" name="amount"
                                value="7000" style="width: 300px !important;" (change)="getQuote()">
                            </div>
                            <div *ngIf="quoteForm.get('amount')?.touched && quoteForm.get('amount')?.errors" class="form-control-error errors m-1">
                                Please Enter Amount..
                            </div>
                        </div>
                    </div> -->
          <!-- <div class="mb-3 text-center justify-content-center">
                        <label for="recipientReceives" class="form-label">Recipient Receives</label>
                        <input type="number" class="form-select styled-input font-weight-bold" id="recipientReceives" value="7000">
                    </div> -->

          <div class="d-flex justify-content-center" *ngIf="toCur" style="width: 66%; background: #d8f3e2; margin: 24px auto; border-radius: 100px; ">
            <p class="text-center mb-0" style="color: black; font-size: 14px;">
              Live Rate :
              <span class="" style="color: #081517"
                >1 {{ toCur.currency_code }} = {{ quote.reverseExchangeRate || 0 | number: "1.2-2" }} INR</span
              >
            </p>
          </div>

          <label for="payerCurrency" class="f-500" style="font-size: 16px !important; color: #081517">Your Currency</label>
          <div
            class="input-group d-flex justify-content-center align-items-center dashboardData"
            style="border-radius: 15px; height: 100%"
          >
            <ng-container>
              <div class="pt-2 p-xs-none custom mt-0" style="padding: 10px; background: #f0f0f0; border-top-right-radius: 0!important; border-bottom-right-radius: 0!important; border-radius: 12px; border: none; height: 52px; color: #081517; display: flex; align-items: center;">
                <img
                  src="/assets/flags/india.svg"
                  class="flag mr-2"
                  alt="Indian Flag"
                  style="height: 20px !important; width: 28px !important; object-fit: cover"
                />
                <span style="font-size: 14px; font-weight: 600">IND</span>
              </div>
            </ng-container>
            <input
              disabled
              type="text"
              class="form-control form-control-lg flex-grow-1 text-right f-700"
              style="background: #f0f0f0; border-top-right-radius: 12px; border-bottom-right-radius: 12px; border: none; height: 52px; color: #081517;  "
              placeholder=" "
              formControlName="toCurId"
              aria-label="Receiver Currency"
              value="₹ {{ quote?.calFromAmount || 0 | number: '1.2-2' }}"
            />
          </div>

          <div class="justify-content-center">
            <p
              class="text-center text-success f-12 mb-2"
              style="color: #212721 !important"
            >
              You will save ₹{{ saving || 0 | number: "1.2-2" }}* compared to
              <span (click)="openComparison()"
                >other platforms</span
              >
            </p>
            <button
              class="btn btn-mod btn-blue mt-4 mb-2 mb-md-0 d-block full-width"
              type="button"
              (click)="signUp()"
            >
              Remit Now
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="w-100 mt-5">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-5 text-sm-left text-center">
            <a href="/pages/privacy-policy" target="_blank" class="text-left fs12" style="cursor: pointer"
              >Privacy Policy</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-fee-comparison-modal #feeComparisonModal></app-fee-comparison-modal>
<app-loading *ngIf="loading"></app-loading>
