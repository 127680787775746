import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-fee-comparison-modal",
  templateUrl: "./fee-comparison-modal.component.html",
  styleUrls: ["./fee-comparison-modal.component.sass"],
})
export class FeeComparisonModalComponent implements OnInit {
  @ViewChild("feeComparisonModal") feeComparisonModal;

  @Input() recipientGets: number;
  @Input() exchangeRate: number;
  @Input() handlingFee: number;
  @Input() youSend: number;
  @Input() bankRate: number;
  @Input() bankFinalAmount: number;
  @Input() totSavings: number;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  showModal() {
    this.open();
  }

  open() {
    console.log("open..");
    this.modalService
      .open(this.feeComparisonModal, {
        size: "md",
      })
      .result.then(
        (result) => {},
        (reason) => {
          console.log("in Dismissed");
        },
      );
  }
}
