import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-modal-body",
  templateUrl: "./modal-body.component.html",
  styleUrls: ["./modal-body.component.sass"],
})
export class ModalBodyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
