import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from "ngx-loading";

const PrimaryWhite = "#FBF9F5";
const SecondaryGrey = "#ccc";
const PrimaryRed = "var(--danger)";
const SecondaryBlue = "var(--primary)";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.sass"],
})
export class LoadingComponent implements OnInit {
  @ViewChild("ngxLoading") ngxLoadingComponent: NgxLoadingComponent;
  public loadingTemplate: TemplateRef<any>;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  @ViewChild("customLoadingTemplate") customLoadingTemplate: TemplateRef<any>;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;

  constructor() {}

  ngOnInit(): void {}

  toggleTemplate(): void {
    if (this.loadingTemplate) {
      this.loadingTemplate = null;
    } else {
      this.loadingTemplate = this.customLoadingTemplate;
    }
  }
}
