<div class="main-card mb-3 card">
  <div class="card-header">
    <h3 class="card-title text-darkBlue">THE REGULATORY SANDBOX (INDIA) AND GLOBAL PRIVACY POLICY</h3>
  </div>
  <div class="card-body">
    <p>Last updated: Apr 01, 2024</p>
    <p>
      Fairex Solutions Pvt Ltd is admitted to the RBI’s Regulatory Sandbox wide its letter CO.DPSS.FD.
      No.S263/08-13-103/2021-2022. All customer onboarding and transaction processing with be conducted by Fairexpay
      within the permitted rules of the RBI’s Regulatory Sandbox.
    </p>

    <h3 class="text-darkBlue h4">CUSTOMER CONSENT:</h3>

    <p>
      Every customer who completes the registration consents that they are doing so being fully aware that Fairexpay
      will undertake their transaction as part of RBI’s Regulatory Sandbox, in association with Fairexpay partners like
      Muthoot, Yes Bank, Ebix cash, Buckzy etc. which is a limited purpose authorization.
    </p>
    <br />
    <p>
      The objective of the Regulatory Sandbox is to foster responsible innovation in financial services, promote
      efficiency and bring benefit to consumers. The Regulatory Sandbox is, at its core, a formal regulatory programme
      for market participants to test new products, services or business models with customers in a live environment,
      subject to certain safeguards and oversight.
    </p>

    <h3 class="text-darkBlue h4">TYPES OF TRANSACTIONS:</h3>
    <ol>
      <li><strong>Trade transactions:</strong> such as cross border ecommerce and digital content.</li>
      <li><strong>All LRS transactions:</strong> Education, family maintenance, Gift, medical tourism, travel etc.</li>
      <li><strong>Capital A/c transactions</strong></li>
    </ol>

    <p>
      Capital account transactions only for portfolio investment in equity/debt permitted subject to condition that the
      bank statement must be obtained for the previous one (1) year from the payer (resident individual) to satisfy the
      AD-I bank regarding the source of funds and no credit facility should be provided to the payer for undertaking
      capital account transactions.
      <span class="f-400">Credit Card funds shall not be accepted for capital account transactions.</span>
    </p>

    <h3 class="text-darkBlue h4">AUTHORISED DEALER CATEGORY I AND CATEGORY II PARTNERS:</h3>

    <p>
      Fairexpay has entered into an agreement with various licensed and regulated AD1 & AD2 service providers to execute
      Sandbox participation. All cross-border transactions will be subject to FEMA and other compliance requirements as
      directed by respective partners on Fairexpay platform.
    </p>

    <h3 class="text-darkBlue h4">WHO CAN USE FAIREXPAY:</h3>

    <p><strong>Individual</strong> - Resident Indian:</p>
    <p>Individuals who are resident Indians and who can digitally validate their identity through Aadhar.</p>

    <p><strong>Businesses</strong></p>
    <p>
      Businesses that are resident Indian and who can digitally validate their identity through various KYB/ KYC API
      stacks available in India.
    </p>

    <h3 class="text-darkBlue h4">RISKS ASSOCIATED WITH THE TRANSACTION:</h3>
    <p>
      KYC documents: Since Fairexpay is conducting these transactions as part of RBI’s Regulatory Sandbox, we have
      limited KYC authentication processes available at our disposal. Hence, an intended consumer, who may not be in
      possession of such specified/required documentation, may not be able to undertake or complete the intended
      transaction on Fairexpay.
    </p>

    <p>
      Transaction completion risk: If a fully on boarded and approved transaction fails to be credited to the intended
      beneficiary, Fairexpay will endeavour to track the same and ensure it is completed. Due to the nature of
      transactions involving multiple parties and different time zones, this process will be completed within 2 weeks to
      3 weeks of such an occurrence being reported by the customer. In the rare case, if the transaction fails to get
      credited to the intended beneficiary (as indicated by the remitter), Fairexpay has ensured that all transactions
      being processed by it under this Regulatory sandbox, is insured for an avg. transaction value of $5000.
    </p>

    <h3 class="text-darkBlue h4">GRIEVANCE REDRESSAL:</h3>

    <p><strong>A customer may raise a grievance and seek resolution through the following mechanism:</strong></p>

    <ul>
      <li>
        a. Log an issue through the following means: <br />
        email: support&#64;mybeacon.ca <br />
        Contact number: +91 9959030960 <br />
        Dashboard: You may log an issue through your dashboard as well. <br />
        Beacon will respond to your grievance within 1 business day (excluding weekends and bank holidays)
      </li>
      <li>
        b. Escalation: If a customer’s grievance is not closed satisfactorily within 7 business days, then the customer
        may escalate the same by emailing at support&#64;mybeacon.ca.
      </li>
    </ul>

    <h3 class="text-darkBlue h4">GLOBAL PRIVACY POLICY</h3>
    <p>
      Fairex Inc(USA) and Fairex Solutions Pvt Ltd(India) and its affiliates (collectively “Fairex”, “Us”, “Our” and
      “We”) respect your privacy and are committed to protecting the privacy of our users, and strive to provide a safe
      user experience regarding any information we may collect during your operation of our website, i.e.
      www.mybeacon.ca (“Website”) or any other clients sub domain sites. This global privacy policy (“Privacy Policy”)
      entails and discusses the types of 'Personal Information/Data' that is collected through your usage of our
      products and services. This Privacy Policy explains what information may be collected on our website, how we use
      this information, and under what circumstances we may disclose the information to third parties. This Privacy
      Policy, however, applies only to information we collect through the Website and does not apply to any third-party
      websites, products, or services, even if they link to our services. Fairex is not responsible for the privacy
      policies or practices of other websites. You may wish to review the privacy policies of those sites so you can
      understand how they collect, use and share your information. SCOPE OF THIS PRIVACY POLICY This Privacy Policy sets
      forth the general rules and policies governing your use of our website. Depending on your activities when visiting
      our website, you may be required to agree to additional terms and conditions. INFORMATION WE COLLECT Our Privacy
      Policy applies to any Customer or Visitor; We collect information when you use our services. Irrespective of where
      you are based, the personal information/data provided to or collected through our services is controlled by Us and
      its protection is our priority. Our data centres are currently located at USA and Canada where the data collected
      is stored under the protection of state-of-the-art cyber security measures. PI COLLECTED/ RECEIVED FROM YOU
      Personal Information/Data (“PI”) is any information that relates to a natural person, which, either directly or
      indirectly, in combination with other information available or likely to be available with a body corporate, is
      capable of identifying such person. The PI that is particularly collected by Us, includes the following:
    </p>
    <ul>
      <li>
        When you register an account with Us - full name, email address, and account log-in credentials are collected
        from you. All passwords are protected and stored in encrypted format, using 'Bcrypt' password encoder.
      </li>
      <li>
        The collected information is passed to our Global Money transfer providers for account creation and one time
        onboarding
      </li>
      <li>
        Information about you that you may provide by filling forms (such as the contact us) or by communicating with Us
        through telephone, e-mail or otherwise. Privileged & Confidential 2
      </li>
      <li>
        In certain scenarios and based on privacy policies for your social media and related services' accounts (i.e.,
        LinkedIn, Twitter, etc.,), you may give Us permission to access certain PI. CUSTOMER SERVICE/ GRIEVANCE
        REDRESSAL We collect information when you contact Us for customer support/ grievance redressal. When you contact
        Our customer support services, we may have to access your mails and other contributions to Our services and
        collect the information we need to categorize your question, respond to it, and, if applicable, investigate any
        breach of our user agreement or this Privacy Policy.
      </li>
    </ul>

    <p>
      We also use this information to track potential problems and trends and customize our support responses to better
      serve you. COOKIES A cookie is a string of information that a website stores on a visitor's computer, and that the
      visitor's browser provides to the website each time the visitor returns. Cookies and other related technologies
      are used for the Website to function effectively. We use authentication cookies to store 'Session ID' & 'Remember
      me ID' at client-side browser while no information is being transferred to Our servers during the process. For
      further queries or information regarding the cookies used by us, please write to us support&#64;mybeacon.ca HOW WE
      USE YOUR PERSONAL INFORMATION PURPOSE OF DATA COLLECTION We ensure that our collection and use of your PI is in
      compliance with applicable law. The PI collected is used to aid smooth business relationships, to comply with
      regulatory and legal obligations, and to pursue our legitimate business interests - in providing payment
      transactions and related services to you. We may also communicate via the email, provided by you, to send
      marketing material related to our products and/or services; to send invites for your participation in events
      and/or surveys; provided, however that the same is sent in accordance with appropriate consent requirements under
      applicable law. We may also use PI collected from you and your device to send advertisements, as allowed under
      applicable law. This PI is collected through the Cookies on our Website, which identify your device, aiding us to
      direct such advertisements.” We may also receive your PI through other publicly available databases or sources and
      combine such data with the PI in our records. This helps us stay up to date, and analyse and expand to provide
      services, which may be of interest to you. SHARING YOUR PI We do not sell, share or rent your PI to unaffiliated
      third parties. We may, however, share your PI in the following scenarios (only indicative and non-exhaustive):
      Privileged & Confidential 3
    </p>
    <ul>
      <li>
        Fairexpay shares information with third parties i.e., our money transfer partners who enable the transaction.
        Certain sensitive PI concerning the data related to bank accounts, value of the transaction etc., is shared with
        our currency exchange partners to fulfil the financial transactions and meet the KYC norms. We assure you that,
        apart from the above-mentioned data, no other personal data like "User ID" or "Password" are shared with any
        third party.
      </li>
      <li>
        In response to legal requirements, enforcement of our policies, compliance with applicable law, enforcement of
        contractual rights, protection of contractual rights, in liaison with judicial or regulatory authorities (as
        required), and to protect your or our property and rights.
      </li>
      <li>
        To other company(ies), in the event we enter into, or intend to enter into, a business relationship resulting in
        merger, joint venture, acquisition, investment or change in control, and/or corporate reorganisation.
      </li>
      <li>
        To third party companies, agents, contractors, service providers, or related companies who, inclusive of: (a)
        verify your identity or receivers of the amounts transferred through our service/Website, if necessary (for your
        queries or related reasons); (b) with fraud prevention agencies who may use such PI to prevent fraud and
        money-laundering. Do note that those who commit fraud may be refused services, financing and/or employment with
        us. Any further queries related to such fraud prevention agencies may be requested at: support&#64;mybeacon.ca.
        TRANSFER OF PI TO OTHER COUNTRIES AND ITS SECURITY We are a global business and We store and process through our
        databases which are currently hosted on Amazon web services (“AWS”) data centre located at East 1, North
        Virginia, USA and Beone Solutions data centre located at Canada. The data privacy rules of a certain country
        maybe different from those of your own; We, however, take measures (including those mentioned below) to ensure
        that any such transfer of PI is done in compliance of applicable laws and as per the standards herein this
        Privacy Policy. The transferred PI is protected via a three (3)-layer security measure: • Network; • Server
        protected by password; and • Database. The user information is hosted in a private zone protected by a 'public
        key and private key' security mechanism to login to server. The database is protected through cyber security
        protocols including a password protection. In addition, the AWS database is not publicly accessible as a VPN is
        required, to access the same. Thus, the security of user information is ensured. WEBSITE VISITOR Privileged &
        Confidential 4 For the proper provision of services and management of the Website, certain non-identifiable
        information such as browser type, language preference, referring site, date and time of each visitor request
        etc., are also collected.

        <br />
        Whether you are a customer of our services and/or a visitor to the Website, do note that we may, from time to
        time, release such non-identifiable information in aggregate (for marketing/trend-display purposes). YOUR
        CHOICES AND RIGHTS You have the right to choose with regard to Our usage of and/or sharing of your PI. If, at
        any point, you wish to no longer receive marketing and/or advertising material from us via email, you may choose
        to opt out by using the unsubscribe button in the email from Us. However, do note that certain important
        administrative information shall still be required to be sent to you. If, at any point, you wish to review,
        edit/correct, or update your PI that you have provided us with, you may choose to do so by signing-in to your
        account OR by contacting Us. Based on the applicable law of your location, you may have the following rights
        with respect to the PI provided by you:
        <ul>
          <li>
            Right to request a confirmation from Us whether or not We process your PI; and if We do, then you may
            request for a copy of your PI in our records.
          </li>
          <li>
            Right to request Us to rectify, edit, or update any part or all of your PI, in cases where the part or all
            of your PI is inaccurate, incomplete or outdated.
          </li>
          <li>Right to request Us to delete/erase your PI from our records, as required by the law.</li>
          <li>
            Right to request Us to restrict the use/sharing of your PI while another request made by you to rectify,
            edit or update is submitted with Us.
          </li>
          <li>
            Right to request Us to export your PI to another company/entity/individual, where required, in order to
            provide our products and/or services to you. If any of your PI – its use/sharing, is based on previously
            received consent from you, you shall have the right to withdraw such provided consent at any given point of
            time. In such a scenario, you may also have the right to oppose any and all use/sharing of your PI. For
            further queries or related exercising of rights, you may contact: support&#64;mybeacon.ca. Do note that We
            will comply with your request to the extent mandated under applicable law. GRIEVANCE REDRESSAL AND RETENTION
            The visitors are free to withdraw consent for usage/sharing of the provided PI. Such a request for
            withdrawal of consent or for deletion of information shall be made by filling a cancellation form and shall
            happen as per applicable law. However, we reserve the right to temporarily store the transaction logs of our
            customers in consonance with financial body regulations for a period required by applicable law. For further
            queries or grievances regarding PI, you may contact: support&#64;mybeacon.ca Privileged & Confidential 5
            Your PI is retained with Us for as long as we are providing services to you, or as required to comply with
            legal, regulatory, and other related obligations. Any such retention, even if beyond the closure of supply
            of services to you, shall be in accordance with the relevant limitation periods and the record retention
            mandates imposed by law. PRIVACY POLICY CHANGES Although most changes are likely to be minor; We may change
            this Privacy Policy from time to time, either in accordance with applicable law and/or at Our sole
            discretion. We visitors to frequently check this page for any changes to Our Privacy Policy. Your continued
            use of this site after any change to this Privacy Policy, will constitute your acceptance of such change
          </li>
        </ul>
      </li>
    </ul>

    <h3 class="text-darkBlue h4">Links to Other Websites</h3>

    <p>
      Our Service may contain links to other websites that are not operated by Us. If You click on a third party link,
      You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site
      You visit.
    </p>
    <p>
      We have no control over and assume no responsibility for the content, privacy policies or practices of any third
      party sites or services.
    </p>

    <h3 class="text-darkBlue h4">Changes to this Privacy Policy</h3>

    <p>
      We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy
      Policy on this page.
    </p>
    <p>
      We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective
      and update the "Last updated" date at the top of this Privacy Policy.
    </p>
    <p>
      You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
      effective when they are posted on this page.
    </p>

    <h3 class="text-darkBlue h4">Contact Us</h3>

    <p>If you have any questions about this Privacy Policy, You can contact us:</p>

    <p class="ml-3 mb-0">
      By email:
      <a href="mailto:support@mybeacon.ca"><span class="text-darkBlue font-italic">support&#64;mybeacon.ca</span> </a>
    </p>
    <p class="ml-3 mb-0">
      By visiting this page on our website:
      <a href="https://www.mybeacon.ca/contactUs.html"
        ><span class="text-darkBlue font-italic">www.mybeacon.ca/contactUs</span></a
      >
    </p>
  </div>
</div>
