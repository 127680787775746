<ng-template #feeComparisonModal let-c="close" let-d="dismiss">
  <div style="background-color: #e1eef3; color: #244349; text-align: center">
    <h4 style="display: inline-block; font-weight: bold; margin: 12px">Fee Comparison</h4>
    <button type="button" class="close p-2" style="float: right" aria-label="Close" (click)="d('Cross click')">
      &times;
    </button>
  </div>

  <div class="modal-body p-0 m-3">
    <div class="comparison-table">
      <table style="width: 100%; background-color: #f1f1f1">
        <thead style="border: none; background-color: #FBF9F5f; text-align: center">
          <tr>
            <th style="padding: 10px">&nbsp;</th>
            <th style="padding: 10px">
              <img src="/assets/images/mybeacon.png" alt="MyBeacon" style="max-width: 120px" />
            </th>
            <th style="padding: 10px">Other platforms</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-left" style="padding: 10px">Recipient gets</td>
            <td class="text-right" style="padding: 10px">
              {{ recipientGets | currency }}
            </td>
            <td class="text-right" style="padding: 10px">
              {{ recipientGets | currency }}
            </td>
          </tr>
          <tr>
            <td class="text-left" style="padding: 10px">Exchange rate</td>
            <td class="text-right" style="padding: 10px">₹{{ exchangeRate | number: "1.4-4" }}</td>
            <td class="text-right" style="padding: 10px">₹{{ bankRate | number: "1.4-4" }}</td>
          </tr>
          <tr>
            <td class="text-left" style="padding: 10px">Handling fee</td>
            <td class="text-right" style="padding: 10px">₹1200.00</td>
            <td class="text-danger text-right" style="padding: 10px">₹2,000.00</td>
          </tr>
          <tr>
            <td class="text-left" style="padding: 10px">You send</td>
            <td class="text-right" style="padding: 10px">₹{{ youSend | number: "1.2-2" }}*</td>
            <td class="text-right" style="padding: 10px">₹{{ bankFinalAmount | number: "1.2-2" }}*</td>
          </tr>
        </tbody>
      </table>
      <div class="mb-2" style="background-color: #dce2fe; padding: 5px; text-align: center; font-weight: bold">
        You saved
        <span class="text-success"> ₹{{ totSavings || 0 | number: "1.2-2" }}*</span>
        compared to other platforms
      </div>
      <div style="font-size: 0.8em; text-align: left; color: #555">
        *Actual savings may vary depending on exchange rate fluctuations and applicable taxes.
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" aria-label="Close" (click)="d('Cross click')" class="btn btn-blue">Close</button>
  </div>
  <app-loading *ngIf="loading"></app-loading>
</ng-template>
