import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-address-details-view",
  templateUrl: "./address-details-view.component.html",
  styleUrls: ["./address-details-view.component.sass"],
})
export class AddressDetailsViewComponent implements OnInit {
  @Input() address;
  constructor() {}

  ngOnInit(): void {}
}
