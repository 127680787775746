import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer-menu",
  templateUrl: "./footer-menu.component.html",
})
export class FooterMenuComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
