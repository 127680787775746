<form class="row row-cols-sm-auto" style="margin-bottom: 12px">
  <div class="col-12">
    <div class="position-absolute" [ngClass]="{ 'd-none': !showDatepicker }">
      <div class="input-group">
        <input
          name="datepicker"
          class="form-control"
          ngbDatepicker
          #datepicker="ngbDatepicker"
          [autoClose]="'outside'"
          (dateSelect)="onDateSelection($event)"
          [displayMonths]="2"
          [dayTemplate]="t"
          outsideDays="hidden"
          [startDate]="fromDate!"
          tabindex="0"
          style="width: 100%; border-radius: 5px; border: none"
        />
        <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
          >
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
    <div class="input-group" style="margin-top: 10px">
      <button
        class="btn btn-blue las fa fa-calendarlas fa fa-calendar"
        (click)="showDatepicker = !showDatepicker; datepicker.toggle()"
        type="button"
        style="border-top-right-radius: 0; border-bottom-right-radius: 0"
      ></button>
      <input
        #dpFromDate
        class="form-control"
        placeholder="From: yyyy-mm-dd"
        name="dpFromDate"
        [value]="formatter.format(fromDate)"
        (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
        style="border-top-right-radius: 0; border-bottom-right-radius: 0; background: #e1e4ff"
      />
      <input
        #dpToDate
        class="form-control"
        placeholder="To: yyyy-mm-dd"
        name="dpToDate"
        [value]="formatter.format(toDate)"
        (input)="toDate = validateInput(toDate, dpToDate.value)"
        style="
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          background: #e1e4ff;
        "
      />
    </div>
  </div>
</form>
