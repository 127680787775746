<div class="app-page-title pb-2 pt-3">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{ icon }}"></i>
      </div>
      <div>
        {{ heading }}
        <div class="page-title-subheading">
          {{ subheading }}
        </div>
      </div>
    </div>
    <div class="page-title-actions">
      <!-- <button type="button" class="btn-shadow mr-3 btn btn-dark">
        <fa-icon [icon]="faStar"></fa-icon>
      </button> -->
      <button
        type="button"
        class="btn-shadow d-inline-flex align-items-center btn btn-green"
        (click)="addClick()"
        *ngIf="enableAddButton"
      >
        <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>
        {{ addButtonTitle }}
      </button>
      <span *ngIf="enableAddButton1">&nbsp;&nbsp;</span>
      <button
        type="button"
        class="btn-shadow d-inline-flex align-items-center btn btn-success"
        (click)="addClick1()"
        *ngIf="enableAddButton1"
      >
        <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>
        {{ addButtonTitle1 }}
      </button>

      <span *ngIf="enableAddButton2">&nbsp;&nbsp;</span>
      <button
        type="button"
        class="btn-shadow d-inline-flex align-items-center btn btn-success"
        (click)="addClick2()"
        *ngIf="enableAddButton2"
      >
        <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>
        {{ addButtonTitle2 }}
      </button>

      <span *ngIf="filter">&nbsp;&nbsp;</span>
      <button
        class="btn-shadow d-inline-flex icon-large align-items-center btn btn-green"
        aria-hidden="true"
        title="filter"
        (click)="filterActionClick()"
        *ngIf="filter"
      >
        <i class="fa fa-filter btn-icon-wrapper"> </i>
      </button>
      <!-- <i class="fa fa-filter font-large m-2" aria-hidden="true" title="filter" (click)="filterActionClick()"  *ngIf="filter"></i> -->
      <span *ngIf="needDownLoad">&nbsp;&nbsp;</span>
      <!-- <i class="fa fa-download font-large m-2" aria-hidden="true" title="Download" (click)="downLoadActionClick()"  *ngIf="needDownLoad"></i> -->
      <button
        class="btn-shadow d-inline-flex icon-large align-items-center btn btn-green"
        aria-hidden="true"
        title="Download"
        (click)="downLoadActionClick()"
        *ngIf="needDownLoad"
      >
        <i class="fa fa-download btn-icon-wrapper"> </i>
      </button>

      <span *ngIf="setting">&nbsp;&nbsp;</span>
      <button
        class="btn-shadow d-inline-flex icon-large align-items-center btn btn-green"
        aria-hidden="true"
        title="setting"
        (click)="settingActionClick()"
        *ngIf="setting"
      >
        <i class="fa fa-gears btn-icon-wrapper"> </i>
      </button>
    </div>
  </div>
</div>
