import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MasterService } from "@app/shared/service/master.service";
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { interval, Subscription } from "rxjs";
import { FreshdeskWidgetService } from "@app/shared/service/freshdesk-widget.service";
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';

@Component({
  selector: "app-sign-up-page",
  templateUrl: "./sign-up-page.component.html",
  styleUrls: ["./sign-up-page.component.sass"],
})
export class SignUpPageComponent implements OnInit {
  timer: string = "60";
  timerSubscription: Subscription;
  year: string;
  signupForm: FormGroup;
  countryList: { countryCode: string, callingCode: string }[] = [];
  countryCodes: { [key: string]: string } = {};
  selectedCountryCode: string = 'CA';
  selectedCallingCode: string = '1';
  sentOtp: boolean = false;
  resentOtp: boolean = false;
  noEdit: boolean = true;

  loading: boolean = false;
  constructor(
    private MasterService: MasterService,
    private router: Router,
    public toasterService: ToastrService,
    private freshdeskWidgetService: FreshdeskWidgetService,
  ) {
    let date = new Date();
    this.year = date.getFullYear().toString();
  }

  intializeForm() {
    console.log("intialize");
    console.log("signupForm", this.signupForm);
    this.signupForm = new FormGroup({
      phoneNo: new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(16)]),
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ),
      ]),
      otp: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });
    console.log("this", this);
  }

  ngOnInit(): void {
    this.intializeForm();
    this.freshdeskWidgetService.hideWidget();
    this.loadCountryCallingCodes();
  }
    loadCountryCallingCodes() {
     const countries = getCountries();
    countries.forEach(country => {
      const callingCode = getCountryCallingCode(country);
      this.countryList.push({ countryCode: country, callingCode });
      this.countryCodes[country] = callingCode;
    });
  }
  
    onCountryChange() {
    this.selectedCallingCode = this.countryCodes[this.selectedCountryCode];
    console.log(`Calling Code: +${this.selectedCallingCode}`);
  }
  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.freshdeskWidgetService.showWidget();
  }

  slideConfig2 = {
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "0",
    slidesToShow: 1,
    arrows: false,
    speed: 500,
    dots: false,
    autoplay: true,
  };

  startTimer(): void {
    this.timerSubscription = interval(1000).subscribe(() => {
      if (+this.timer > 0) {
        this.timer = (+this.timer - 1).toString();
        if (+this.timer < 10) {
          this.timer = "0" + this.timer;
        }
      } else {
        this.timerSubscription.unsubscribe();
        if (this.sentOtp) this.resentOtp = true;
      }
    });
  }

  sendOtp(): void {
    console.log(this.signupForm.get("phoneNo"));

    if (!this.signupForm.get("phoneNo").errors) {
      console.log("OTP Sent");
      let phoneNo = this.signupForm.get("phoneNo").value;
      this.loading = true;
      let temp: any = {};
      temp.phoneNo = phoneNo;
      temp.phoneNo = `+${this.selectedCallingCode} ${phoneNo}`;
      this.MasterService.signupOtp(temp).subscribe(
        (res) => {
          this.toasterService.success(res?.msg, "Success", {
            positionClass: "toast-top-right",
          });
          this.timer = "60";
          this.resentOtp = false;
          this.sentOtp = true;
          this.noEdit = false;
          this.startTimer();
          this.loading = false;
        },
        (err) => {
          console.log("Some error occured" + JSON.stringify(err));
          this.toasterService.error(this.MasterService.getError(err), "Error", {
            positionClass: "toast-top-right",
          });
          this.loading = false;
        },
      );
    }
  }
  editNo() {
    this.timerSubscription.unsubscribe();
    this.resentOtp = false;
    this.sentOtp = false;
    this.noEdit = true;
  }

  signup(): void {
    if (this.signupForm.valid) {
      this.loading = true;
      let temp: any = this.signupForm.getRawValue();
      temp.phoneNo = `+${this.selectedCallingCode} ${temp.phoneNo}`;
      console.log("temp" + JSON.stringify(temp));
      console.log(temp);

      this.MasterService.saveSignup(temp).subscribe(
        (res: any) => {
          this.loading = false;

          console.log("Login Validation Staart");
          this.toasterService.success(res?.msg, "Success", {
            positionClass: "toast-top-right",
          });
          localStorage.setItem("bearer", res.token.accessToken);
          this.MasterService.getUser().subscribe((res) => {
            console.log("userInfo" + JSON.stringify(res.lrsUser));
            localStorage.setItem("user", JSON.stringify(res.lrsUser));
            localStorage.setItem("name", JSON.stringify(res.userName));
            if (res) {
              let url = "customer/dashboard";
              this.loading = false;
              this.router.navigateByUrl("app/pages/" + url);
            }
          });

          console.log(res);
          this.sentOtp = false;
          this.resentOtp = false;
          this.noEdit = true;
          this.intializeForm();
        },
        (err) => {
          this.loading = false;
          this.toasterService.error(this.MasterService.getError(err), "Error", {
            positionClass: "toast-top-right",
          });
        },
      );
    } else {
      this.signupForm.markAllAsTouched();
    }
  }

  login() {
    this.router.navigateByUrl("/pages/login");
  }

  checkDefault() {
    let phoneNo = this.signupForm.get("phoneNo").value;
    if (!phoneNo || phoneNo.length == 0) {
      phoneNo = "+91 ";
      this.signupForm.get("phoneNo").setValue(phoneNo);
    }
  }
}
