<div #myDiv class="form-group rounded has-float-label">
  <label [for]="id">
    {{ label }}
  </label>

  <input
    #textarea
    type="text"
    (blur)="touched(textarea.value)"
    [id]="id"
    [name]="name"
    (input)="change($event)"
    (blur)="blur($event)"
    [disabled]="formControl?.disabled"
    [value]="formControl?.value"
    class="form-control"
    [ngClass]="isRequired() && !formControl?.disabled ? 'form-control-mandatory' : ''"
  />

  <div *ngIf="formControl?.touched && !formControl?.valid" class="errors mt-1">
    <div *ngIf="hasError('required')">Please enter the required value.</div>

    <div *ngIf="!otherErrors && hasError('maxlength')">
      The value must not exceeds
      {{ formControl?.errors.maxlength.requiredLength }} characters.
    </div>

    <div *ngIf="!otherErrors && hasError('minlength')">
      The value must not be less than
      {{ formControl?.errors.minlength.requiredLength }} characters.
    </div>

    <div *ngIf="!otherErrors && hasError('emailValidator')">Please enter a value email.</div>

    <div *ngIf="!otherErrors && hasError('invalidformat')">Please enter the required details correctly</div>

    <div *ngIf="!otherErrors && hasError('NaN')">NaN issue</div>
  </div>
</div>
