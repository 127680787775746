<div class="row no-gutters">
  <div class="d-none d-lg-block col-lg-5">
    <div class="slider-light">
      <!--      -->
      <ngx-slick-carousel class="" #slickModal="slick-carousel" [config]="slideConfig2">
        <div ngxSlickItem>
          <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-white">
            <div class="slide-img-bg"></div>
            <div class="slider-content text-dark">
              <div class="mb-2 text-center d-inline">
                <img src="assets/images/Slider1.png" width="400px" />
              </div>
              <h3>Explore the world with MyBeacon</h3>
            </div>
          </div>
        </div>
        <div ngxSlickItem>
          <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-white">
            <div class="slide-img-bg"></div>
            <div class="slider-content text-dark">
              <div class="mb-2 text-center d-inline">
                <img src="assets/images/Slider2.png" width="400px" />
              </div>
              <h3>Remit money without borders</h3>
            </div>
          </div>
        </div>
        <div ngxSlickItem>
          <div class="position-relative h-100 d-flex justify-content-center align-items-center bg-white">
            <div class="slide-img-bg opacity-6"></div>
            <div class="slider-content text-dark">
              <div class="mb-2 text-center d-inline">
                <img src="assets/images/Slider3.png" width="400px" />
              </div>
              <h3>Securely pay your university fees using MyBeacon.</h3>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>

  <div class="d-flex align-items-center col-md-12 col-lg-7">
    <!-- Company Logo -->
    <div class="col-lg-12 m-2" style="    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;">
      <div>
        <div
          class="text-md-right text-sm-left mt-3 mb-5 pr-2 d-flex justify-content-center justify-content-center"
        >
          <h3 class="text-center fs20">Beacon Profile</h3>
        </div>
        <div class="text-left">
          <h3 class="f-500 text-dark mb-2 " style="font-size: 28px;">Set your Mobile Number</h3>
          <p>We’ll use your mobile number to verify your payment transactions, and provide status updates.</p>
          <div class="mb-3">
            <label for="mobileNumber" class="form-label sm-label mb-0" style="font-size: 12px; color: #636763">Mobile Number</label>
            
            <div class="d-flex flex-column flex-md-row align-items-md-center">
              <div class="col-md-7 p-0 mb-2 mb-md-0">
                <!-- ----------INICIO---------- -->
                 <div class="input-group d-flex justify-content-center align-items-center dashboardData" style="">
            <ng-select
              class="pt-2 p-xs-none landing custom mt-1 flex-grow-1"
              [(ngModel)]="selectedCountryCode"
              (change)="onCountryChange()"
              style="
                background: #e0e2df!important;
                padding: 0px !important;
                margin: 0px !important;
                width: 10px !important;
                border-radius: 12px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                border: none;
              "
            >
            <!-- (change)="getQuote()" -->
              <ng-container *ngFor="let country of countryList">
                <ng-option [value]="country.countryCode">
                  
                  <span style="font-weight: 400; font-size: 16px;" class="pl-2">{{ country.countryCode }}: +{{ country.callingCode }}</span>
                </ng-option>
              </ng-container>
            </ng-select>

            <input
              type="tel"
              class="form-control form-control-lg flex-grow-1 text-right"
              style="background: #e0e2df; border-top-right-radius: 12px; border-bottom-right-radius: 12px; border: none; height: 52px; font-size: 16px;"
              [(ngModel)]="phoneNo"
              (focus)="checkDefault()"
                  minlength="13"
                  maxlength="15"
            />
          </div>
                 <!-- ---------FIN--------- -->
                <!-- <span
                  *ngIf="sentOtp"
                  id="timer"
                  class="timer f-500"
                  style="position: absolute; cursor: pointer; top: 50%; transform: translateY(-50%); right: 10px"
                  (click)="editNo()"
                  ><i class="fas fa fa-pencil text-darkBlue"></i
                ></span> -->
                <!-- <lable *ngIf="!noEdit" class="form-control styled-inputBox mr-2">{{ phoneNo }}</lable> -->
              </div>
            </div>
            <div *ngIf="phoneNoError" class="form-control-error errors m-1">
              Please enter valid phone no with countrycode
            </div>
          </div>
  
          
  
          <div class="mb-3" *ngIf="sentOtp">
            <div class="col-md-7 p-0 position-relative">
              <input type="text" class="form-control styled-inputBox mr-2" [(ngModel)]="otp" placeholder="OTP" />
              <span
                id="timer"
                class="timer f-500"
                style="position: absolute; top: 50%; transform: translateY(-50%); right: 10px"
                >00:{{ timer }}</span
              >
            </div>
            <div *ngIf="otpError" class="form-control-error errors m-1">Please enter valid OTP</div>
          </div>
        </div>
      </div>
      <div class="col-md-3 p-0 p-md-3 m-0 ml-md-0 mt-3 mt-md-0">
        <button *ngIf="sentOtp" class="btn btn-blue fw-normal w-xs-100 mb-2 mb-sm-0" type="button" (click)="login()">
              Login
            </button>
              <button *ngIf="noEdit" class="btn btn-mod btn-blue fw-normal w-100" type="button" (click)="sendOtp()">
                Send OTP
              </button>
              <!-- <button *ngIf="sentOtp" class="btn btn-mod btn-blue fw-normal w-100" type="button" (click)="editNo()">Edit</button> -->
              <button *ngIf="resentOtp" class="btn btn-mod btn-blue fw-normal  w-100" type="button" (click)="sendOtp()">
                Resend OTP
              </button>
    </div>
  </div>
</div>
<app-loading *ngIf="loading"></app-loading>
