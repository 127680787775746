import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BaseLayoutComponent } from "./Layout/base-layout/base-layout.component";
import { PagesLayoutComponent } from "./Layout/pages-layout/pages-layout.component";

// // Pages

import { AuthGuard } from "./shared/guard/auth.guard";

import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { TermsConditionComponent } from "./pages/terms-condition/terms-condition.component";
import { LandingPageComponent } from "./pages/landing-page/landing-page/landing-page.component";
import { LoginPageComponent } from "./pages/login-pages/login-page/login-page.component";
import { SignUpPageComponent } from "./pages/login-pages/sign-up-page/sign-up-page.component";
import { SsoRouterComponent } from "./pages/login-pages/sso-router/sso-router.component";
import { SSODataCollectComponent } from "./pages/login-pages/sso-data-collect/sso-data-collect.component";
const routes: Routes = [
  {
    path: "",
    component: BaseLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "app",
        loadChildren: () => import("./pages/pages.module").then((m) => m.PagesModule),
      },

      {
        path: "",
        redirectTo: "app/pages/customer/dashboard",
        pathMatch: "full",
      },
    ],
  },
  {
    path: "",
    component: PagesLayoutComponent,
    children: [
      // User Pages
      {
        path: "pages/privacy-policy",
        component: PrivacyPolicyComponent,
        data: { extraParameter: "" },
      },
      {
        path: "pages/terms-condition",
        component: TermsConditionComponent,
        data: { extraParameter: "" },
      },

      {
        path: "pages/landingPage",
        component: LandingPageComponent,
        data: { extraParameter: "" },
      },
      {
        path: "pages/signUpLRS",
        component: SignUpPageComponent,
        data: { extraParameter: "" },
      },
      { path: "pages/ssoAuth/:ssoToken", component: SsoRouterComponent },
      {
        path: "pages/login",
        component: LoginPageComponent,
        data: { extraParameter: "" },
      },
      { path: "pages/ssoData/:uuid", component: SSODataCollectComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
