import { Component, OnInit } from "@angular/core";
import { ThemeOptions } from "../../../../../theme-options";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-drawer",
  templateUrl: "./drawer.component.html",
})
export class DrawerComponent implements OnInit {
  faFile = faFile;
  faCloudDownloadAlt = faCloudDownloadAlt;
  faFilePdf = faFilePdf;
  faFileExcel = faFileExcel;
  faCheck = faCheck;
  faTrash = faTrash;
  faEllipsisH = faEllipsisH;

  toggleDrawer() {
    this.globals.toggleDrawer = !this.globals.toggleDrawer;
  }

  constructor(public globals: ThemeOptions) {}

  ngOnInit() {}
}
