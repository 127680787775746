import {
  Component,
  OnInit,
  forwardRef,
  Renderer2,
  Input,
  Output,
  EventEmitter,
  SkipSelf,
  Host,
  Optional,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  OnChanges,
} from "@angular/core";

import { NG_VALUE_ACCESSOR, ControlContainer, NgControl } from "@angular/forms";
import { NgSelectComponent } from "@ng-select/ng-select";

import { CustomInputComponent } from "./../custom-input/custom-input.component";
@Component({
  selector: "app-custom-select",
  templateUrl: "./custom-select.component.html",
  styleUrls: ["./custom-select.component.sass"],
})
export class CustomSelectComponent extends CustomInputComponent implements OnChanges {
  @Input() items;
  @Input() name;
  @Input() id;
  @Input() placeholder;
  @Input() value;
  @Input() class;
  @Output() changeAction = new EventEmitter();

  selected: any;

  ngOnChanges(changes: SimpleChanges) {
    // console.log(this.items);
    // this.selected = '1';
    // this.selected = this.items.filter((n) => n.value == this.value)[0]
    //   ? this.items.filter((n) => n.value == this.value)[0]
    //   : null;
    // console.log(this.selected);
  }

  change(e) {
    // console.log(e);
    this.changeAction.emit(e);
  }

  close(e) {
    // console.log('dropdown close:::', e);
  }
}
