<div class="main-card mb-3 card">
  <div class="card-header">
    <h3 class="card-title">Terms and Conditions</h3>
  </div>
  <div class="card-body">
    <p>Last updated: April 01, 2024</p>
    <p>Please read these terms and conditions carefully before using Our Service.</p>
    <p>
      The use of the www.mybeacon.ca, services including Remittance Facility (defined hereinafter) and tools are
      governed by the following terms and conditions (these terms and conditions shall be referred to as "Terms and
      Conditions" hereinafter) as applicable to the www.mybeacon.ca including the applicable policies which are
      incorporated herein by way of reference. If You transact on the www.mybeacon.ca, You shall be subject to the
      policies that are applicable to the www.mybeacon.ca for such transaction. By mere use of the www.mybeacon.ca, You
      shall be contracting with the Company and these terms and conditions including the policies constitute Your
      binding obligations, with the Company. For the purpose of these Terms of Use, wherever the context so requires
      "You" or "User" shall mean any natural or legal person who has agreed to become a purchaser, seller or payer on
      the www.mybeacon.ca by providing information and data on the www.mybeacon.ca as required for using the computer
      systems. The Company allows the User to browse the www.mybeacon.ca but does not allow the user to make purchases,
      sale and remittance without providing the information as required from a natural or legal person for transactions
      qua 'Foreign Exchange products' on the www.mybeacon.ca or otherwise. When You use any of the services provided by
      us through the www.mybeacon.ca, You will be subject to the rules, guidelines, policies, terms, and conditions
      applicable to such service, and they shall be deemed to be incorporated into these Terms and Conditions and shall
      be considered as part and parcel of these Terms and Conditions. We reserve the right, at our sole discretion, to
      change, modify, add or remove portions of these Terms and Conditions, at any time without any prior written notice
      to You. It is Your responsibility to review these Terms and Conditions periodically for updates / changes. Your
      continued use of the www.mybeacon.ca following the posting of changes will mean that You accept and agree to the
      revisions or updates as the case may be. As long as You comply with these Terms and Conditions, We grant You a
      personal, non-exclusive, non-transferable, limited privilege to enter and use the www.mybeacon.ca. Use of the
      www.mybeacon.ca is available only to persons who can form legally binding contracts under The Indian Contract Act,
      1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including but
      not limited to minors, un-discharged insolvents etc. are not eligible to use the www.mybeacon.ca. If You are a
      minor i.e. under the age of 18 years, You shall not be eligible to be a User of the www.mybeacon.ca and shall not
      transact on or use the www.mybeacon.ca. As a minor if You wish to use or transact on the www.mybeacon.ca, such use
      or transaction may be made by Your legal guardian or parents on the www.mybeacon.ca. The Company reserves the
      right to refuse to provide You with access to the www.mybeacon.ca if it is brought to our notice or if it is
      discovered that You are under the age of 18 years. Overview of Remittance Facility The Remittance Facility (as
      defined below) is made available by the AD1/ AD2 on the www.mybeacon.ca and further described below and elsewhere
      on the www.mybeacon.ca, among other things, provide a Registered User with a technology platform to place requests
      for Remittance Facility and also track the status through the www.mybeacon.ca. In order to avail of the facilities
      described above the Registered User should make the transfer of funds from the Registered User's own resident
      individual account maintained with his/her AD1 in India. Remittance Facility requests can only be made by the
      Registered User for the purposes permitted by the AD1/ AD2.
    </p>
    <h3 class="text-darkBlue h4">Fees and Charges</h3>
    <p>
      The AD1/ AD2 can set its own fees and charges for facilities (including the Remittance Facility) and may revise
      the same at any time and the availability of facilities displayed or offered on or through the www.mybeacon.ca are
      subject to change. The AD1/ AD2 shall give prior notice of 30 days for implementing such changes in fees and
      charges. Nothing contained on the www.mybeacon.ca constitutes an offer, promise or commitment to grant or provide
      any facility. While the AD1/ AD2 endeavours to post accurate and updated information on the www.mybeacon.ca, the
      Registered User must verify the same before taking any action or entering into any transaction. Fairexpay does not
      control and is not responsible in any manner for any fees or charges, if any, that may be imposed by any AD1/ AD2s
      involved in the international wire transfer, including but not limited to correspondent or Beneficiary AD1/ AD2
      such financial institution(s) or any other third parties in connection with the wire transfer. Registered Users
      acknowledge and understand that when the funds are remitted by a Registered User, Fairexpay would be required to
      effect the conversion at the applicable exchange rate prevailing on the day of conversion, subject to availability
      of exchange rate. The Registered User agrees that the prevailing exchange rate is the exchange rate applicable at
      the time of conversion of funds which will be applicable for processing the user's transaction request. Neither
      the Registered User nor the Beneficiary/ recipient will be entitled to any interest for the period during which
      the funds to be remitted are with the AD1/ AD2, are in the course of remittance, or for any other period.
    </p>
    <h3 class="text-darkBlue h4">Eligible Users</h3>
    <p>
      In order to access and use the product and avail of the Remittance Facility, the Registered User must be an
      individual of at least 18 years of age having the capacity to enter into legally binding contracts under the law
      applicable to the Registered User. The Registered User must be a resident individual holding a AD1 account with
      any AD1 in India, must hold a Pan card and Aadhaar card. Further the customer/s whose account has been classified
      as delinquent before shall not be eligible to avail the Remittance Facility. Residents of India (collectively,
      "Residents") shall access, use and avail of the www.mybeacon.ca and the remittance facilities only to the extent
      the domestic laws of India and such countries permit them to access, use and avail of the www.mybeacon.ca and the
      facilities. All non-residents understand that by accessing, using and availing of the www.mybeacon.ca and the
      facilities, they may be violating local laws in India and/or such other countries. The Registered User agrees that
      he/she will be solely and absolutely liable for any liability incurred in this regard and the Registered User will
      indemnify the AD1/ AD2 against any liability incurred by the AD1/ AD2 in this regard.
    </p>
    <h3 class="text-darkBlue h4">Registration Information</h3>
    <p>
      Only limited access to the www.mybeacon.ca is available to non-Registered Users. Non-Registered Users are not
      permitted to avail the Remittance Facility. In order to obtain increased access to the www.mybeacon.ca and in
      order to avail of the Remittance Facility, every Person who is desirous of availing such facilities is required to
      register on the www.mybeacon.ca as a Registered User. Subject to the other Facility T&amp;C, upon registration as
      a Registered User, the www.mybeacon.ca will register the relevant Registered User ID and Registered User Password.
      The Registered User hereby agrees that he shall not get registered more than once in the www.mybeacon.ca for
      availing the Remittance Facility. The Registered User agrees to provide true, accurate, current and complete
      information, as prompted by the registration form (for self or Beneficiary) on the www.mybeacon.ca, and to update
      this information to keep it true, accurate, current and complete at all times. If, in the judgment of or to the
      knowledge of the AD1/ AD2, any information provided by the Registered User is untrue, inaccurate, not current or
      incomplete, the AD1/ AD2 retains the right to terminate the Registered User's registration and refuse the
      Registered User access to or use of the www.mybeacon.ca or any facilities, including the cancellation of pending
      transactions. The AD1/ AD2 reserves the right to request the Registered User, at any time and from time to time,
      for additional information and/or proof of authenticity of any information; the Registered Users continued access
      to the www.mybeacon.ca and the facilities, including the completion of any ongoing transaction, may be subject to
      the receipt by the AD1/ AD2 of such additional information or proof thereof. The Registered User will be solely
      and absolutely responsible for maintaining the secrecy and confidentiality of all Registered User IDs and
      Registered User Passwords and be fully responsible and liable for all transactions and activities that occur under
      the Registered Users ID and using the Registered Users Password, including any unauthorised use or misuse of such
      Registered User ID and/or Registered User Password and the AD1/ AD2 shall have no obligation to verify the
      authenticity of any such transaction or activity. In consideration of the AD1/ AD2 agreeing to provide the
      Remittance Facility as per the Facility T&amp;C, the Registered User hereby unconditionally agrees to indemnify
      the AD1/ AD2 and hold the AD1/ AD2 harmless against any liability, costs, damages, claims, suits, and proceedings
      based upon or relating to such unauthorized access and use. Without prejudice to the aforesaid, the Registered
      User agrees to log out from his or her Registered User Account at the end of each session and to immediately
      notify the AD1/ AD2 via Email on the www.mybeacon.ca, or through the Call Centre, or registered post ad of any
      suspected loss, theft, unauthorised usage of the Registered User ID or Registered Users Password, any other breach
      of security, or any receipt by the Registered User of confirmation of a transaction, funds or other activity which
      the Registered User did not authorise; or any inaccurate information in the Registered Users account balances or
      transaction history. If the Registered User forgets his/her Registered User Password, the AD1/ AD2 may, subject to
      verification and the AD1/ AD2s satisfaction as to the Registered Users identity, allow the Registered User to
      reset his/her password or enable user ID as per the process detailed on the www.mybeacon.ca. The AD1/ AD2 reserves
      the right to reject registration of payer as a Registered User if the AD1/ AD2 is not satisfied with results of
      the Know Your Customer (“KYC”) and anti-money laundering diligence checks conducted by the AD1/ AD2. The AD1/ AD2
      retains the right to terminate the registration of any Registered User and refuse the Registered User access to or
      use of the www.mybeacon.ca or any facilities, including the cancellation of pending transactions, as per its
      discretion.
    </p>

    <h3 class="text-darkBlue h4">Acceptable Use</h3>
    <p>
      A Registered User/Visitor shall not: Use or access the www.mybeacon.ca or any facility to do, facilitate or
      support any act that violates the Facility T&amp;C or any rules and regulations of any jurisdiction, including but
      not limited to rules and regulations relating to money laundering, illegal gambling activities, fraud, or funding
      of terrorist organizations. Restrict or inhibit any other Person from accessing, using and enjoying the
      www.mybeacon.ca or the facilities. Modify, copy, distribute, transmit, display, perform, publish, licence, create
      derivative works from, transfer or sell any information, designs, logos, trademarks, software, facilities,
      products or services obtained on or through the www.mybeacon.ca, except as permitted by the copyright owner or
      other right holder thereof. Post or transmit any unlawful, fraudulent, libellous, defamatory, obscene,
      pornographic, profane, threatening, abusive, hateful, offensive, or otherwise objectionable information or
      statement of any kind including, without limitation, any information or statement constituting or encouraging
      conduct that would constitute a criminal offence, give rise to civil liability, or otherwise violate any local,
      state, national, foreign or other law. Post or transmit any advertisements, solicitations, chain letters, pyramid
      schemes, investment opportunities or schemes or other unsolicited commercial communication (except as otherwise
      expressly permitted by the AD1/ AD2 in writing) or engage in spamming or flooding. Post or transmit any
      information or software which contains a virus, trojan horse, worm or other harmful component. Post, publish,
      transmit, reproduce, distribute or in any way exploit any information, software or other material obtained from or
      through the www.mybeacon.ca for commercial purposes. Upload, post, publish, transmit, reproduce or distribute in
      any way, information, software or other material obtained on or through the www.mybeacon.ca which is protected by
      copyright or other proprietary right, or derivative works with respect thereto, except as permitted by the
      copyright owner or other right holder thereof. Upload, post, publish, reproduce, transmit or distribute in any way
      any component of the www.mybeacon.ca itself or derivative works with respect thereto, except as permitted by the
      AD1/ AD2 or the copyright owner or other right holder thereof, the www.mybeacon.ca being copyrighted under the
      relevant laws. Attempt to decompile or reverse engineer any of the software available on the www.mybeacon.ca. Make
      any attempt to hack into the www.mybeacon.ca or otherwise attempt to subvert any firewall or other security
      measure of the www.mybeacon.ca and if the Registered User/ Visitor becomes aware of any shortcoming in the
      security on the www.mybeacon.ca the Visitor shall forthwith inform the AD1/ AD2 of the same in writing. Reproduce,
      duplicate, copy, sell, resell or exploit for any commercial purposes, any portion of the www.mybeacon.ca, use of
      the facilities, or access to the www.mybeacon.ca or the facilities, other than as provided within the scope of the
      service. Disclose to any other Person, in any manner whatsoever, any information of a confidential nature relating
      to the AD1/ AD2 or the affiliate partners obtained in the course of using or accessing the www.mybeacon.ca or
      availing of any facility
    </p>
    <h3 class="text-darkBlue h4">Limitations of Offering</h3>
    <p>
      The AD1/ AD2 shall not be under any duty to assess the prudence or otherwise of any instruction or transaction
      given or entered by the Registered User. Nothing provided on the www.mybeacon.ca should be construed as advice of
      any nature and the Registered Users are advised to consult professionals in this regard prior to taking any
      decision. The Registered User hereby authorizes the AD1/ AD2 to verify any information provided. The AD1/ AD2
      shall not be responsible for any error or omission made by the Registered User in keying in or otherwise providing
      the information required to avail of the Remittance Facility or any consequences as a result of such error or
      omission. If you provide an incorrect account number for the Beneficiary/ recipient or an incorrect identification
      number for the Beneficiary/recipients financial institution, you may lose the entire amount of the funds
      transferred and the AD1/ AD2 will not be liable for the same in any manner whatsoever. The AD1/ AD2 shall
      endeavour to carry out the requests received under the Remittance Facility. However, the AD1/ AD2 shall be
      entitled, in its sole and absolute discretion, to refuse to comply with all or any of a Registered Users
      instructions or requests without assigning any reason. The Registered User cannot amend, cancel or revoke any
      request made to the AD1/ AD2 under the Remittance Facility on submitting the transaction request and successfully
      transferring the funds to the advised account of the AD1/ AD2. In the event the Registered User desires to revoke
      /cancel after successfully submitting the transaction for processing, the AD1/ AD2 may accept such cancellation at
      its discretion and on a best effort basis, provided that exchange loss incurred by the AD1/ AD2 in this connection
      shall be reimbursed. The AD1/ AD2 will be entitled to debit such amounts due to the AD1/ AD2 from the funds paid
      to the AD1/ AD2 by the Registered User for executing such transaction. Any scheme for awarding or securing reward
      points or other rewards or prizes (by whatever name called) is available only to Persons who are eligible for the
      same under the rules of the concerned scheme and the applicable laws of India and other concerned jurisdictions.
      The Registered User is therefore required to verify the eligibility of the Registered User before participating in
      any such scheme. The AD1/ AD2 is not responsible or liable for the performance, quality or any other aspect of any
      rewards, prizes or items manufactured or supplied by third parties against any redemption of the points or
      otherwise pursuant to such scheme, and the Registered User's recourse for the same will be only against the
      Persons who have manufactured or supplied the same. The AD1/ AD2 shall be entitled to have more than one
      promotional offer in existence and applicable at any given time. However, a Registered User shall be entitled to
      avail of only one offer per transaction and in case of multiple transactions under a given offer, he/she shall be
      eligible only for the first eligible transaction. It shall be entirely at the discretion of the AD1/ AD2 to
      consider any exceptions to the above. The AD1/ AD2 has no control over, and is not responsible for, the quality,
      safety, legality, or delivery of any goods or services that the Registered User may pay for using the
      www.mybeacon.ca and/or the facilities. Such use will be at the Registered Users own risk. In order to avail of
      specific facilities, the Registered User may have to agree to other terms and conditions in addition to the
      Facility T&amp;C and may also have to execute agreements, undertakings, declarations, powers of attorney and other
      writings and abide by the procedures as may be specified by the AD1/ AD2. The AD1/ AD2 retains the right to
      terminate the registration of any Registered User and refuse the Registered User's access to or use of the
      www.mybeacon.ca or any facilities, including the cancellation of pending transactions, as per its discretion.
    </p>
    <h3 class="text-darkBlue h4">Disclaimers</h3>
    <p>
      The www.mybeacon.ca, all information on or available through the www.mybeacon.ca and the facilities provided by
      AD1/ AD2 are made available to the Registered Users as is and without any representation or warranty of any kind,
      either express or implied, including without limitation, any representation or warranty for accuracy, continuity,
      uninterrupted access, timeliness, sequence, quality, performance, fitness for any particular purpose,
      completeness, title, compatibility, warranty of non-infringement, freedom from computer virus, warranties of
      merchantability or fitness for a particular purpose, each to the extent permissible by applicable law. Although
      the AD1/ AD2 adopts security measures that it considers appropriate for the www.mybeacon.ca, it does not represent
      or warrant that the www.mybeacon.ca is immune from denial of service attacks, unauthorised access or other illegal
      subversion of these security measures that might impair the working of the www.mybeacon.ca, the availability of
      the facilities or the integrity and confidentiality of Registered User's personal and login information or
      accounts and the AD1/ AD2 disclaims liability arising from any such acts or occurrences. Furthermore, while the
      AD1/ AD2 takes reasonable precautions to avoid such eventualities, and the Registered User understands that
      communications through internet are not always secure and agrees that the AD1/ AD2 shall not be responsible for
      any unauthorised or illegal interception of E-mail or other communication to or from the AD1/ AD2. Services are
      subject to the activities taken by other international AD1/ AD2s and Service Providers involved in the wire
      transfer. The AD1/ AD2 disclaims any responsibility for any delay or failure caused due to any action of
      international AD1/ AD2s and Service Providers. Information and facilities on the www.mybeacon.ca may be displayed
      and offered in a phased manner at the discretion of the AD1/ AD2. The AD1/ AD2 shall have the right to, from time
      to time and in its discretion, introduce new information and facilities and add to, modify, or withdraw any
      information or facility or the terms thereof in whole or in part. While the AD1/ AD2 makes every endeavour to
      ensure that the facilities are offered to a Registered User without interruption or delay, continued provision of
      the facilities may be impractical or impossible due to the failure of operational systems for reasons including
      but not limited to virus attacks, natural calamity, floods, fire and other natural disasters, legal restraints,
      faults in the telecommunication network or network failure, system maintenance, software or hardware error, labour
      problems, strikes or any other reason beyond the control of the AD1/ AD2, and the AD1/ AD2 makes provides no
      guarantee that any instructions or transactions will definitely be communicated, processed or performed (whether
      or not before a particular time) using the www.mybeacon.ca or the facilities. The AD1/ AD2 operates and offers the
      www.mybeacon.ca and the facilities strictly on a no-liability and no-obligation basis as regards the AD1/ AD2, and
      the AD1/ AD2 shall not be liable to the Registered User or any other third party for any claim for direct,
      indirect, incidental, special, exemplary, punitive, consequential or other damages (including, without limitation,
      loss of profits, loss or corruption of data, loss of profit or goodwill, work stoppage, computer failure or
      malfunction, or interruption of business) whether arising under contract, tort or any other theory arising out of
      or in connection with the www.mybeacon.ca, the inability to access the www.mybeacon.ca or any facilities, products
      or services mentioned or advertised on or accessed or availed on or through the www.mybeacon.ca. The Registered
      Users remedies for errors or unauthorized transactions shall be limited to those arising under the applicable laws
      and regulations, and as described on the www.mybeacon.ca and in the communications provided by the AD1/ AD2 to the
      Registered User. To the extent any of the disclaimers or limitations of AD1/ AD2's liability in the Facility
      T&amp;C are held to be unenforceable, the maximum liability of the AD1/ AD2 to the Registered User shall not
      exceed the amount of fees paid/payable by the Registered User to the AD1/ AD2 for the specific facility availed by
      the Registered User through the www.mybeacon.ca and under an issue. The Registered User/ Visitor/ Referrer shall
      ensure that he/she shall not be in violation of any data protection laws in force at the time of referring
      potential users for the service to the AD1/ AD2 or when registering for the Remittance Facility (as the case may
      be).
    </p>
    <h3 class="text-darkBlue h4">Facilities Provided by Third Parties</h3>
    <p>
      Some of the facilities mentioned or offered on or through the www.mybeacon.ca require the Registered User to
      independently contract for and use services provided by third party Service Providers and the AD1/ AD2 makes no
      warranty on behalf of such third parties. The AD1/ AD2 shall not be liable for any loss or damage of any sort
      incurred as a result of any transactions or dealings between the Registered Users and such third party Service
      Providers. The Registered User may be charged for such transactions /dealings by such third party Service
      Provider. Such third party Service Providers may have their own agreements, terms and conditions which will govern
      the services provided by them, which may be specific to each such Service Provider and vary from facility to
      facility. The AD1/ AD2 recommends that the Registered User obtain the full text of such terms and warranties from
      such Service Providers before availing of any such service. All of the Registered User's rights and remedies and
      all maintenance, update, warranty, liability or any other obligations related to any such services shall be
      governed by the applicable terms, policies and procedures of the concerned Service Provider and the AD1/ AD2 shall
      not be liable or responsible for the same in any manner. The Registered User shall have no claim, rights or
      remedies whatsoever against the AD1/ AD2 in respect of any such services availed or sought to be availed by the
      Registered User from any such Service Provider. The Registered User agrees not to hold the AD1/ AD2 liable for any
      loss or damage of any sort incurred as a result of any such dealings with any Service Provider.
    </p>
    <h3 class="text-darkBlue h4">Terms of specific facilities</h3>
    <p>
      The following (among other) facilities are proposed to be offered on the www.mybeacon.ca. However, all the
      facilities or all the various components and features of a facility may not be offered at all times. The AD1/ AD2
      may discontinue, modify, change, and alter any of the features of the facility at its sole discretion. Alerts: the
      AD1/ AD2 shall endeavour to ensure that Alerts are communicated to the Registered User in accordance with the
      Registered User's instructions. However, neither the AD1/ AD2 nor the Service Providers would be responsible or
      liable for non-dispatch or delay in dispatch of the Alerts by the AD1/ AD2 / Service Providers or any delay in
      receipt or non-receipt of the Alerts for any reason whatsoever. Under no circumstances shall the AD1/ AD2 and/or
      the Service Providers be liable for any costs, damages or other amount whatsoever for such non-dispatch or delay
      in dispatch or any non-receipt or delay in receipt of the Alerts. Non- receipt of Alerts will not discharge or
      reduce the Registered User's liability to pay any amount to the AD1/ AD2 which would have been payable in the
      event of proper receipt of the Alerts. Remittance Facility: subject to the relevant regulatory approvals, terms
      and conditions imposed while granting the necessary approvals and other terms of the Remittance Facility, such
      facility enables the Registered User to avail of a technology platform for initiating outward remittances. The
      AD1/ AD2 shall, under the Remittance Facility, convert the funds received from the Registered User in to the
      relevant currency of the Beneficiary as instructed by the Register User. The Registered User should pay the total
      remittance amount including any charges and taxes as may be applicable, as per the instructions stated on the
      www.mybeacon.ca by an acceptable mode described on the www.mybeacon.ca. The funds will be remitted from India only
      after matching the name of the Registered User against the sender name as mentioned in the electronic fund
      transfer message received by the AD1/ AD2. Thereafter, after deducting the appropriate charges/fees as may be
      applicable at the time of remittance, the money will be remitted to the Beneficiary AD1/ AD2 account in the
      Relevant Country as designated by the Registered User. The AD1/ AD2 will process the request only upon receipt of
      clear funds from the remitters account and a swift message will be sent to the correspondent AD1/ AD2. The AD1/
      AD2 will provide confirmation on processing the transaction, which shall mean that the AD1/ AD2 has sent the swift
      message to the correspondent AD1/ AD2. The AD1/ AD2 will not be able to confirm whether the Beneficiary account
      has been credited, however, if the funds are returned, then status will be updated accordingly and the funds will
      be refunded to the same account from which funds were received after deducting applicable charges. The AD1/ AD2
      will process the remittance request only if the funds are received in the designated account of the AD1/ AD2
      within the validity period as mentioned on submitting the transaction along with the transaction's unique
      reference number. In the event, the funds are received after the validity period the AD1/ AD2 reserves the right
      to cancel the transaction as per its sole discretion. Validity period for the purpose of this clause shall mean
      the period up to which the funds are received by the AD1/ AD2 for processing the transaction. On expiry of the
      validity period, the AD1/ AD2 reserves the right to either process the transaction on receiving the funds at the
      prevailing exchange rate applicable at the time of conversion or may reject the transaction and return the funds
      to the remitting account at its sole discretion. While it shall be the AD1/ AD2's endeavour to adhere to the time
      schedule indicated by it on the www.mybeacon.ca, the AD1/ AD2 hereby disclaims liability or responsibility for any
      changes in the time schedule for execution of the Registered User's instructions or remittance or credit of funds
      for any reason, except as otherwise required by applicable law or regulation and as described to the Registered
      User on the www.mybeacon.ca and in communications provided by the AD1/ AD2 to the Registered User. The AD1/ AD2
      shall in no way be held responsible and/or be liable for any queries, errors, disputes or delays in messaging,
      money transmission, currency conversion, conversion rates offered, payment to the beneficiaries of the remittances
      or any other query, claim or dispute. The AD1/ AD2 will, however, use reasonable efforts to assist the Registered
      User in contacting the AD1/ AD2 or any third party to facilitate resolution of such queries, claims and disputes.
      The Registered User hereby authorizes the AD1/ AD2 to check or to use a third party agency to check the Registered
      User's credit history/identity details at the time of and/or prior or subsequent to enrolment. The Registered User
      hereby agrees that, where the AD1/ AD2 so deems advisable or necessary as per its due diligence criteria, the AD1/
      AD2 may hold the money (to be remitted to the Relevant Country) for longer than the usual time for remittance, and
      may delay the remittance. The Registered User hereby agrees that the AD1/ AD2 has no control over when the
      Beneficiary's financial institution (through whom the Beneficiary chooses to receive the remittance) makes such
      funds available for the Beneficiary's use and the AD1/ AD2 shall not be responsible for any delay or default on
      the part of such financial institution. The Registered User hereby agrees that the purpose of the remittances
      supported by the Remittance Facility will be strictly limited to the purposes as permitted on the www.mybeacon.ca
      at the time of using the Remittance Facility. The purpose declared by the Registered User while using the
      Remittance Facility will be treated as final and binding and cannot be changed at a later date by the Registered
      User or the intended Beneficiary. The regulatory reporting by the AD1/ AD2 in India will be done on the basis of
      the purpose selected by the Registered User through the Remittance Facility. The Registered User shall provide to
      the AD1/ AD2 all the information as may be required, in absence of which the Registered User shall not avail of
      the Remittance Facility. The Registered User hereby undertakes that by sending funds to the Relevant Country ,
      he/she is not violating any exchange control laws stipulated by the Government of India and/or the Reserve AD1/
      AD2 of India and the rules prevailing in such destination country. The Registered User agrees to keep the AD1/ AD2
      indemnified for any such violation the Registered User hereby acknowledges that the obligation shall lie on the
      Registered User to ensure that there are no violations of any law, regulation, authorization including the limits
      on outward remittances listed in the Foreign Exchange Management Act, 1999 of India or exchange control and the
      Registered User shall be solely liable for any violation thereof. The Registered User hereby agrees and
      acknowledges that the maximum value for any remittance transaction or the cumulative values of a number of
      remittance transaction using the Remittance Facility shall not exceed the limit of USD 250,000 for a particular
      financial year or such other limit that may be communicated by the AD1/ AD2 to the Registered User from time to
      time. Besides, the Registered User will be able submit requests for foreign outward remittance through net-AD1/
      AD2ing within the applicable third-Party funds transfer limit only, as applicable to his/her account. The AD1/ AD2
      may specify to the Registered User through the www.mybeacon.ca from time to time the limit on the funds that can
      be transferred from the Registered Users account to the Beneficiary account. The Registered User acknowledges that
      he/she has read, understood and complied with all laws and regulation including those mentioned in the Declaration
      and the AD1/ AD2 shall not be responsible for any non-compliance of the limits mentioned therein by the Registered
      User. The AD1/ AD2 shall assume no responsibility for the performance of any entity involved in the process; and
      for any loss or damage incurred or suffered by the Registered User for any error, defect, failure or interruption
      of the facility or consequences arising out of delayed payments. The AD1/ AD2 shall not be responsible for
      impounding of funds by any regulators/ regulatory authority in the Relevant Country.
    </p>
    <h3 class="text-darkBlue h4">Others</h3>
    <p>
      The Registered User understands that submission of request does not necessarily imply processing of the
      transaction. The AD1/ AD2 has full rights to reject the transaction based on regulatory and internal guidelines.
      The Registered User will ensure that the limit submitted is correct and has not been exhausted in the current
      financial year for any of the accounts held individually or collectively with any AD1/ AD2. The Registered User
      authorizes the AD1/ AD2 to debit the savings AD1/ AD2 account together with applicable charges and effect the
      foreign exchange remittance directly (applicable only for CONTRACTED AD1/ AD2 India account holders). The
      Registered User understands that on submitting the request if the transaction reference number is not displayed
      then the user should check if the E-mail or SMS confirmation is received by him/her for successful submission of
      the request. Applicable for CONTRACTED AD1/ AD2 India account holders only. The Registered User understands that
      on submitting the request if the service request number is not displayed then he should check if the email
      confirmation is received for successful submission of the request and should check if the debit is posted in the
      account. Any re-initiation without confirming will result in duplicate debit into his/her account. The Registered
      User agrees to adhere to the turn-around time and cut off timings for raising the request. Funding the account for
      remittance to be processed as per informed timelines on the www.mybeacon.ca the above cut off timing is applicable
      on working days (presently Monday to Friday excluding AD1/ AD2 holidays in India and the country of remittance).
      The Registered User confirms that he/she is not a foreign national. Further, this facility is only for telegraphic
      transfer/wire transfer. The Registered User agrees and acknowledges that the AD1/ AD2 is not liable in case the
      funds are not credited or credited with the delay for any reason at Beneficiary AD1/ AD2/intermediary AD1/ AD2's
      end for any query or suggestion kindly contact our customer service desk. The exchange rate applicable to the
      remittance request will be applicable only if the designated account of the AD1/ AD2 is funded by the Registered
      User within the validity period. The AD1/ AD2 will process the transaction request only if the funds are
      transferred from a self-resident savings account of the remitter, in case the funds are transferred from a joint
      account, the Registered User must be the primary account holder and does not require any permission from the joint
      account holder. If the funds are received from an account where the Registered User is not primary account holder
      and the name does not appear in the fund transfer details received from his/her AD1/ AD2, AD1/ AD2 would reject
      such transaction and return the funds within 7 working days.
    </p>
    <h3 class="text-darkBlue h4">Referral Transactions</h3>
    <p>
      The transactions in relation to the recommendations/ referrals made by the Referrer will be AD1/ AD2's
      transactions (“Referral Transactions”) and the Referrer understands that any decision made by the AD1/ AD2 in
      relation to the Referral Transactions shall be final. The Referrer agrees to assist and conduct its searches to
      confirm the legality and validity of the KYC documents in relation to the Referral Transactions in accordance with
      the instructions provided by the AD1/ AD2. The AD1/ AD2 will be responsible for the compliance of the KYC Master
      Directions (Master Direction DBR.AML.BC. No.81/14.01.001/2015-16) for the purpose of such Referral Transactions.
      Further, the Referrer agrees to provide any other information/documents in relation to the individuals or Persons
      referred by it as may be stipulated by the AD1/ AD2 from time to time and the AD1/ AD2 in its sole discretion may
      accept/reject any referral made by the Referrer and the AD1/ AD2 will have absolute discretion to use the referral
      in any manner it deems appropriate and for any of the products or services offered by the AD1/ AD2 or the
      subsidiaries / Referrers of the AD1/ AD2. The Referral Transactions will be subject to applicable Nostro Account
      charges for the payments made to the Beneficiary and shall be in accordance with Article 3. The Alliance Partner
      will not be held liable for any claims/complaints or liabilities arising in relation to the Referral Transactions.
      If a Person/ customer/ Referrer's complaint comes to the Alliance Partner, the Alliance Partner will within 1
      (one) business day transfer the complaint to the AD1/ AD2. Any refund under the Referral Transaction will be on
      the basis of return credit received in the Nostro Account, and subject to deduction of any conversion charges and
      in accordance with Article 3. In case of any refunds due to return from overseas, the customer fee, GST, Tax
      Collected at Source (“TCS”) or any other amounts will not be refunded. Standard charges applicable and also
      corresponding taxes will be applicable upon refunds, while converting the amount back to Indian currency, in
      accordance with Article 3. TCS collected by AD1/ AD2 under the Referral Transactions, will not be refunded under
      any circumstances and the customer/ Registered User/ Person may adjust the same during their ITR filing. Customer/
      Registered User will be responsible for correctness of the information provided for the Referral Transaction, any
      rejection/delay due to incorrect details provided, will not be the responsibility of the AD1/ AD2 or the Alliance
      Partner. The customer declaration taken from the customer, shall be correct and the customer shall be responsible
      for the correctness of the same. The Customer will make payments in relation to the Referral Transactions from its
      own account, any payment made by a third-party account will be rejected by the AD1/ AD2. Permissible Outward
      Remittance Payments The Registered User agrees that it will only request a remittance payment that complies with
      the Facility T&amp;C and the applicable laws of India and applicable law in the Relevant Country of the
      Beneficiary. The Registered User may use the Remittance Facility to send remittance payments on ihis/her own
      behalf. The Registered User may not resell the Registered User Account access or use the Remittance Facility to
      make remittance payments on behalf of, or as agent for, another Person. The Registered User undertakes that the
      AD1/ AD2 account, details provided to the AD1/ AD2, pertain to his/her own resident savings bank account, and the
      funds being remitted are his/her own funds and such funds and are not originating from an NRO (non-resident
      ordinary) account. The Registered User agrees that it will not request a remittance payment that would result in a
      violation of the laws or regulations of India and/or applicable law in the country of the Beneficiary.
    </p>
    <h3 class="text-darkBlue h4">Proprietary and Intellectual Property Rights</h3>
    <p>
      The AD1/ AD2 is the owner and/or authorized user of any trademark, registered trademark and/or service mark
      appearing on the www.mybeacon.ca, and is the copyright owner or licensee of the content and/or information on the
      www.mybeacon.ca including but not limited to any text, links, images, logos, illustrations, audio clips, video
      clips, screens and product and Registered Users or any Visitor shall not download and/or save a copy of the
      www.mybeacon.ca or any part thereof including any of the screens or part thereof and/or reproduce, store it in a
      retrieval system or transmit it in any form or by any means - electronic, electrostatic, magnetic tape, mechanical
      printing, photocopying, recording or otherwise including the right of translation in any language without the
      express permission of the AD1/ AD2 (except as otherwise provided on the www.mybeacon.ca or in the Facility T&amp;C
      for any purpose) or use or modify it in any manner that is likely to cause confusion or deception among Persons or
      in any manner disparages or discredits the AD1/ AD2 or any Service Providers. However, Visitors may print a copy
      of the information on this www.mybeacon.ca for the Personal use or records. The AD1/ AD2, the Alliance Partners
      and/or the Service Providers do not grant any license to or other authorization, and none should be implied or
      presumed, for use of their respective trademarks, registered trademarks, service marks, or other copyrightable
      material or other intellectual property by placing them on the www.mybeacon.ca. This www.mybeacon.ca is for the
      Visitors personal use. If the Visitor makes any other use of this www.mybeacon.ca, except as otherwise provided
      above, the Visitor may violate copyright, trademark and other laws of India and other countries, and may be
      subject to penalties.
    </p>
    <h3 class="text-darkBlue h4">Use of Information</h3>
    <p>
      By agreeing to the Facility T&amp;C, the Registered User and the Visitor consents to the AD1/ AD2s privacy policy,
      a copy of which may be found on the www.mybeacon.ca. The Registered User/ Visitor undertakes and authorises the
      AD1/ AD2, its group companies to exchange, share or part with all the information, data or documents relating to
      his/her application to other CONTRACTED AD1/ AD2 India group companies / AD1/ AD2s / financial institutions /
      credit bureaus / agencies / statutory bodies /tax authorities /central information bureaus/ such other Persons as
      the AD1/ AD2 / its group companies may deem necessary or appropriate as may be required for use or processing of
      the said information / data by such Person/s or furnishing of the processed information / data / products thereof
      to other AD1/ AD2s / financial institutions / credit providers / users registered with such Persons and shall not
      hold the AD1/ AD2 and/ or its group companies liable for use of this information. I/ we hereby irrevocably
      authorise the AD1/ AD2 to extract my/our call records or related information as may have been disclosed by me/ us
      with/ to any third party Service Providers in relation to services and facilities offered to me/us by such third
      party Service Providers on account of my/ our existing AD1/ AD2ing relationship with the AD1/ AD2. From time to
      time, it will be necessary for Registered User(s)/ Visitors to supply the AD1/ AD2 with data in connection with
      the opening or continuation of accounts or the provision of services and facilities. Failure to supply such data
      may result in the AD1/ AD2 being unable to provide any of the above services or facilities. It is also the case
      that data is collected from Registered User/ Visitors in the ordinary course of AD1/ AD2ing relationship, for
      example, when Registered User/ Visitors write cheques, deposit money or apply for credit. This includes
      information obtained from credit reference agencies. The purpose(s) for which Registered User/ Visitor data may be
      used are as follows: i. The processing of applications for, and the daily operation of the services and credit
      facilities provided to Registered User/ Visitors; ii. Conducting credit checks (including without limitation upon
      an application for consumer credit and upon periodic review of the credit) and data verification; iii. Assisting
      other financial institutions to conduct credit checks and collect debts; iv. Ensuring ongoing creditworthiness of
      Registered User/ Visitors; v. Designing financial services or related products for Registered User/ Visitors' use;
      vi. Marketing financial services or related products to Registered User/ Visitors; vii. Determining the amount of
      indebtedness owed to or by Registered User/ Visitors; viii. Creating and maintaining the AD1/ AD2's credit and
      risk related models; ix. Collection of amounts outstanding from Registered User/ Visitors and bills providing
      security for Registered User/ Visitors obligations; x. Meeting the requirements to make disclosure under the
      requirements of any law, rule, regulation, order, ruling, judicial interpretation or directive (whether or not
      having the force of law) applicable to the AD1/ AD2 or (any of its branches) and its agents and affiliates; xi.
      Enabling an actual or proposed assignee of the AD1/ AD2, or participant or sub participant of the AD1/ AD2's
      rights in respect of the Registered User/ Visitor to evaluate the transaction intended to be the subject of the
      assignment, participation or sub participation; xii. Any other purposes permitted by law; and xiii. Purposes
      relating to any of the above. Data held by the AD1/ AD2 relating to Registered User/ Visitors will be kept
      confidential but the AD1/ AD2 may, at its sole discretion, provide such information to the categories of Persons
      set out below. The Registered User/ Visitor hereby irrevocably authorises the AD1/ AD2 to disclose, as and when
      the AD1/ AD2 is required to do so by applicable law or when the AD1/ AD2 regards such disclosure as necessary or
      expedient (including but not limited to disclosures for the purpose of credit review of any account or the
      facilities availed by the Registered User/ Visitor with the AD1/ AD2 or any of its branches whether singly or
      jointly with others or otherwise), any information relating to the Registered User/ Visitor, his account(s) or
      other assets or credit facilities whatsoever held on the Registered User/ Visitor's behalf to:- i. The head
      office, affiliates or any other branches or subsidiaries of the AD1/ AD2; ii. His auditors, professional advisers
      and any other Person(s) under a duty of confidentiality to the AD1/ AD2; iii. Vendors, installers, maintainers or
      servicers of the AD1/ AD2's computer systems; iv. Any exchange, market, or other authority or regulatory body
      having jurisdiction over the AD1/ AD2, its head office or any other branch of the AD1/ AD2 or over any
      transactions effected by the Registered User/ Visitor or for the Registered User/ Visitor's account; v. Any party
      lawfully authorised by law to make such demand or request; vi. Any Person with whom the AD1/ AD2 contracts or
      proposes to contract with regard to the sale or transfer or sharing of any of its rights, obligations or risks
      under the Facility T&amp;C; vii. Any Person (including any agent, contractor or third party Service Provider) with
      whom the AD1/ AD2 contracts or proposes to contract with regard to the provision of services and/or facilities in
      respect of the Registered User/ Visitor's (as the case may be) or in connection with the operation of the AD1/
      AD2's business; viii. Any Person employed with, or engaged as an agent by, the AD1/ AD2 or its head office or
      affiliates, including any relationship officers, whether in India or elsewhere, for the purposes of or in
      connection with interactions with the Registered User/ Visitors or providing services to the Registered User/
      Visitors or processing transactions pertaining to the Registered User/ Visitors' accounts or facilities; ix. (to
      enable the AD1/ AD2 to centralise or outsource its data processing and other administrative operations) to the
      AD1/ AD2's head office, its affiliates or third parties engaged by the AD1/ AD2 for any such services/operations;
      x. In case of a wire transfer to the Beneficiary AD1/ AD2 to whom the transfer is being made. Xi. A drawee AD1/
      AD2 providing a copy of a paid cheque (which may contain information about the payee) to the drawee; xii. A Person
      making payment into the Registered User/ Visitor's account (by providing a copy of a deposit confirmation slip
      which may contain the name of the Registered User/ Visitor); xiii. Credit reference agencies and, in the event of
      default, debt collection agencies; xiv. Any Person to whom the AD1/ AD2 is under an obligation to make disclosure
      under the requirement of any law binding on the AD1/ AD2 or any of its branches; and xv. Any Person to whom
      disclosure may be required by applicable law and regulation. The Registered User/ Visitor hereby agrees and
      consents that the AD1/ AD2 shall be entitled, in connection with the Registered User/ Visitor's application for
      any account, facilities or services provided by the AD1/ AD2, or during the course of the Registered User/
      Visitor's relationship with the AD1/ AD2, to obtain and procure information pertaining to the Registered User/
      Visitor any of his/ her/ its accounts, legal or financial position from whatever sources available to the AD1/
      AD2. Data may be transferred overseas pursuant to the provisions of this clause. The information disclosed
      pursuant to this clause may be subject to further disclosure by the recipient to other parties in accordance with
      the laws of the country in which the recipient is located. Such laws may be wider in scope and implemented under
      less restrictive terms than would otherwise be the case in India due to the difference in applicable laws and
      regulations.
    </p>
    <h3 class="text-darkBlue h4">No Agency or AD1/ AD2 Relationship</h3>
    <p>
      The Facility T&amp;C and the use by the Registered User or access to the www.mybeacon.ca or any facilities are not
      intended to create any agency, partnership, joint-venture, employer-employee or AD1/ AD2-customer relationship
      between the Registered User or the Beneficiary of the one part and the AD1/ AD2 or Service Provider of the other
      part, except where otherwise specifically agreed or appointed. Specifically, the use of Remittance Facility does
      not establish a checking account or any other form of a AD1/ AD2 account for the Registered User or the
      Beneficiary with the AD1/ AD2 and neither does it constitute or create a fiduciary or escrow capacity between the
      Visitor or the Beneficiary and the AD1/ AD2. Funds held while processing a remittance payment request are not
      insured by any government authority. None of the facilities offered hereby is a funds transfer or transmission
      service, and a Registered User's request or instruction while using a facility is not a payment order or other
      request for a funds transfer.
    </p>
    <h3 class="text-darkBlue h4">Indemnity</h3>
    <p>
      Each Visitor and/or Registered User agrees to defend, indemnify and hold the AD1/ AD2, and Service Providers and
      each of their respective affiliates, directors, officers and employees, harmless from any and all claims,
      liabilities, damages, costs, expenses and proceedings, including reasonable attorneys' fees, arising in any way
      from the Visitors or Registered User's use of the www.mybeacon.ca, whether in India or any other jurisdiction, the
      inaccuracy or incompleteness of registration information, or the placement or transmission of any message,
      information, software or other materials through the www.mybeacon.ca by the Visitor, the Registered User or users
      of the Registered User's ID and password or related to any violation of the Facility T&amp;C by the Visitor or
      Registered User or users of the Registered User's ID and password, and any claims dispute or differences between
      the Visitor or Registered User and any supplier of services that are part of the facilities. The Registered User
      hereby agrees unconditionally to indemnify the AD1/ AD2 and hold the AD1/ AD2 harmless against any liability,
      costs, damages, claims, suits, and proceedings based upon or relating to such untrue/false/misleading information.
    </p>
    <h3 class="text-darkBlue h4">Suspension or Termination of Access</h3>
    <p>
      The Registered User acknowledges and agrees that the AD1/ AD2 may, without notice, suspend or terminate the
      Registered User's ID, password or account or deny the Registered User access to all or part of the www.mybeacon.ca
      or any facilities if the Registered User engages in any conduct or activity that the AD1/ AD2, in its sole
      discretion, believes violates any of the Facility T&amp;C, violates the rights of the AD1/ AD2, or is otherwise
      inappropriate for continued access, or if the AD1/ AD2 learns of the Registered User's death, AD1/ AD2ruptcy or
      lack of legal capacity or of circumstances that impact the Registered User's creditworthiness or for any other
      reason which the AD1/ AD2 thinks fit and proper. Until suspension or termination of the Registered User's ID takes
      effect, the Registered User will remain responsible for any transactions entered into using the Registered User's
      ID and all obligations incurred thereby or otherwise by the Registered
    </p>
    <h3 class="text-darkBlue h4">Governing Law and Jurisdiction</h3>
    <p>
      The www.mybeacon.ca, the facilities, the Facility T&amp;C, all transactions entered into on or through the
      www.mybeacon.ca or through the availment of any facility and the relationship between the Registered User/Visitor
      and the AD1/ AD2 shall be governed by and construed in accordance with the laws of India and no other nation,
      without regard to the laws relating to conflicts of law. The Registered User and the AD1/ AD2 agree that all
      claims, differences and disputes (collectively, “Disputes”) arising under or in connection with or pursuant to the
      www.mybeacon.ca, the availment of any facility, the Facility T&amp;C, any transactions entered into on or through
      the www.mybeacon.ca or through the availment of any facility or the relationship between the Registered User and
      the AD1/ AD2 shall be subject to the exclusive jurisdiction of the competent courts located in India and the
      Registered User hereby accedes to and accepts the jurisdiction of such courts. Notwithstanding the aforementioned,
      if the AD1/ AD2 thinks fit, the AD1/ AD2 may institute proceedings against a Registered User in any other court or
      tribunal having jurisdiction under applicable law. Disputes shall include not only claims made directly by the
      Visitor, but also made by anyone connected with the Visitor or claiming through a Visitor, such as a recipient of
      funds. Disputes include not only claims that relate directly to the AD1/ AD2, but also its successors, assignees,
      employees, and agents, and claims for which the AD1/ AD2 may be directly or indirectly liable, even if it is not
      properly named at the time the claim is made. Disputes include claims based on any theory of law, contract,
      statute, regulation, tort (including fraud or any intentional tort), or any other legal or equitable ground.
      Disputes include claims made as part of a class action or other representative action, it being expressly
      understood and agreed to that the resolution of such claims must proceed on an individual (non-class,
      non-representative) basis. The AD1/ AD2 hereby disclaims all liability, direct or indirect, for non-compliance
      with the laws of any jurisdiction other than India. The fact that it is technologically possible to access or use
      the www.mybeacon.ca and avail of any facility in any jurisdiction other India should not in itself suggest that it
      is legal to do so. It shall be the sole responsibility of the Visitor to verify whether the www.mybeacon.ca and
      the facilities can be legally and legitimately accessed, utilized and availed in the relevant jurisdiction and the
      Visitor understands that by accessing, using and availing of the www.mybeacon.ca and the facilities, he or she may
      be violating the local laws in India and/or such jurisdiction. Further, the AD1/ AD2 hereby explicitly disclaims
      any responsibility, direct or indirect, for non-compliance by a Visitor of the laws of any jurisdiction
      whatsoever.
    </p>
    <h3 class="text-darkBlue h4">No Waiver</h3>
    <p>
      The failure or delay of the AD1/ AD2 to exercise or enforce any right or provision of the Facility T&amp;C shall
      not constitute a waiver of such right or provision. No waiver on the part of the AD1/ AD2 shall be valid unless it
      is in writing signed by or on behalf of the AD1/ AD2. A waiver of any right or provision by the AD1/ AD2 on a
      particular occasion shall not prevent the AD1/ AD2 from enforcing such right or provision on a subsequent
      occasion.
    </p>

    <h3 class="text-darkBlue h4">Severability</h3>
    <p>
      If any provision of the Facility T&amp;C shall be held to be invalid or unenforceable by reason of any law or any
      rule, order, judgement, decree, award or decision of any court, tribunal or regulatory or self-regulatory agency
      or body, such invalidity or unenforceability shall attach only to such provision or condition, and the Visitor,
      Registered User, the AD1/ AD2, the court, tribunal or regulatory or self-regulatory agency or body should
      endeavour to give effect to the parties' intentions as reflected in the provision to the extent possible. The
      validity of the remaining provisions and conditions shall not be affected thereby and these Facility T&amp;C shall
      be carried out as if any such invalid or unenforceable provision or condition was not contained herein.
    </p>

    <h3 class="text-darkBlue h4">Limitation</h3>
    <p>
      Notwithstanding any statute or law to the contrary, but to the extent permitted by law, any claim or cause of
      action arising out of or related to access or use of the www.mybeacon.ca or any facility or the Facility T&amp;C
      must be filed within three (3) months after such claim or cause of action arose failing which it shall be forever
      barred.
    </p>

    <h3 class="text-darkBlue h4">Notices</h3>
    <p>
      The AD1/ AD2 may give notice to the Registered User by E-mail, letter, telephone or any other means as the AD1/
      AD2 may deem fit to the address last given by the Registered User. Notices under the Facility T&amp;C may be given
      to the AD1/ AD2 by the Registered User in writing by delivering them by hand or by sending them by post to the
      AD1/ AD2's address mentioned on the www.mybeacon.ca. The AD1/ AD2 may, but shall not be bound to, act upon notices
      and instructions given by the Registered User to the AD1/ AD2 by E-mail, letter, telephone or any other means as
      the AD1/ AD2 may deem fit. In addition, the AD1/ AD2 may (but shall not be bound to) also publish notices of
      general nature, which are applicable to all Visitors and/or Registered Users in a newspaper circulating in India
      and/or on its www.mybeacon.ca. Such notices will have the same effect as a notice served individually to each
      Visitor and/or Registered User. Documents which may be sent by electronic communication between the parties may be
      in the form of an e-mail, an e-mail attachment, or in the form of an available download from the www.mybeacon.ca.
      The AD1/ AD2 shall be deemed to have duly communicated and delivered any communication or document to the
      Registered User if such communication or document is sent via e-mail to the e-mail address provided by the
      Registered User to the AD1/ AD2. The AD1/ AD2 shall also be entitled to act on the basis of any instructions
      received or purported to be received by the AD1/ AD2 from the Registered User by e-mail or other electronic means
      or via the internet. The AD1/ AD2 shall also be entitled (but not bound) to act upon fax instructions and
      communications.
    </p>

    <h3 class="text-darkBlue h4">Miscellaneous</h3>
    <p>
      The AD1/ AD2 may sub-contract or employ agents to carry out any functions or services relating to the
      www.mybeacon.ca, any facility or any of its obligations under the Facility T&amp;C. In order to access the
      www.mybeacon.ca and avail of the facilities, the Visitor will, at the Registered Users own cost, have to: Obtain
      access to the world wide web and pay any service fees, telephone charges and online service usage associated
      therewith, and; ii. Obtain access to all equipment necessary for the Registered User to connect to the world wide
      web, including a computer and modem. The content presented on or through the www.mybeacon.ca may vary depending
      upon the Visitors browser limitations and the AD1/ AD2 disclaims any responsibility for the unavailability of any
      facility or any error in processing of a transaction through the www.mybeacon.ca caused by the Visitors browser
      limitations. All communications under these Facility T&amp;C shall be conducted in the English language.
    </p>

    <h3 class="text-darkBlue h4">Definitions and Interpretations</h3>
    <p>
      In these Facility T&amp;C, unless the context otherwise requires, the following words and phrases shall have the
      meanings assigned to them hereunder: "Alerts" means notices relating to various matters issued or to be issued by
      the AD1/ AD2 to the Visitor from time to time e.g. notices of market information, reminders for bill payments,
      etc. "Alliance Partner" means any Person, other than the AD1/ AD2, who displays content or offers any facility on
      the www.mybeacon.ca. "Beneficiary" means a person resident outside India as defined in Foreign Exchange Management
      Act (FEMA), 1999, for all purposes except for remittance to own account abroad and whose details have been
      provided by the Registered User to the AD1/ AD2 for the transfer of funds. "Beneficiary AD1/ AD2" means the AD1/
      AD2 in the Relevant Country, with whom the Beneficiary holds an account, and which has been so mentioned by the
      Registered User. "Call Centre" means the call centre which may be set up by the AD1/ AD2 or a Service Provider to
      assist Visitors/Registered Users in connection with the www.mybeacon.ca including the facilities displayed or
      offered thereon and any transactions entered into or proposed to be entered into by the Visitors in respect of
      such facilities. "Declaration" means a declaration submitted by the Registered User at the time of registration
      and transaction in the form and manner acceptable to the AD1/ AD2. “Nostro Account” means a AD1/ AD2 account
      established in a foreign country usually in the currency of that country for the purpose of carrying out
      transactions there. "Person" includes a resident individual. "Referrer" means an individual or a Person, who may
      be an existing Registered User, who refers/recommends/suggests to another individual or Person the services
      offered by the AD1/ AD2, irrespective of whether the concerned individual/Person avails the service. "Registered
      User" means any Visitor who has registered himself, herself or itself with the www.mybeacon.ca in the appropriate
      manner or utilises service offered through this www.mybeacon.ca by the virtue of holding a AD1/ AD2 account with
      the AD1/ AD2. "Registered User Account" means an online account created by Registered User on the www.mybeacon.ca
      to avail the Remittance Facility. "Registered User ID" means the username that is chosen by the Registered User
      and registered by the www.mybeacon.ca which, along with the Registered User Password, will enable the Registered
      User to - Avail of facilities and enter transactions in respect of the facilities; Access its Registered User
      Accounts, download application forms for facilities and register a change in address; and Do any other acts as the
      www.mybeacon.ca may permit. "Registered User Password" means the password that is chosen by the Registered User
      and registered by the www.mybeacon.ca for access to the www.mybeacon.ca which, along with the Registered User ID,
      will enable the Registered User to - Avail of facilities and enter transactions in respect of the facilities
      offered on the www.mybeacon.ca; Access its Registered User Accounts, download application forms for facilities
      change in nominee details, payments, payment of dues, bill payments, trading and make online applications register
      a change in address; and Do any other acts as the www.mybeacon.ca may permit. "Relevant Country” means a country
      where the Registered User may transfer funds to the Beneficiary under the Remittance Facility. "Remittance
      Facility" means an instruction for transfer of funds from the Registered Users account in India to a Beneficiary
      in Relevant Country through the www.mybeacon.ca for select purposes detailed herein, the services provided by the
      AD1/ AD2 on the www.mybeacon.ca for the transmission of funds by a Registered User to a Beneficiary in the
      Relevant Country. "Service Provider" means a Person who provides a service to the AD1/ AD2 in order to enable the
      AD1/ AD2 to operate and/or maintain the www.mybeacon.ca, provide any feature on the www.mybeacon.ca or provide any
      facility which is provided by the AD1/ AD2. "Visitor" means any Person who accesses or visits the www.mybeacon.ca,
      whether or not such Person has registered himself as a Registered User. In these Facility T&amp;C, unless the
      context otherwise requires: Words of any gender are deemed to include each other gender; Words using the singular
      or plural also include the plural or singular, respectively; The terms "hereof", "herein", "hereby", "hereto" and
      derivative or similar words refer to this entire www.mybeacon.ca; The term "article" refers to the specified
      clause of these Facility Terms and Conditions; Headings and bold typeface are only for convenience and shall be
      ignored for the purposes of interpretation; Reference to any legislation or law or to any provision thereof shall
      include references to any such law as it may, after the date hereof, from time to time, be amended, supplemented
      or re-enacted, and any reference to a statutory provision shall include any subordinate legislation made from time
      to time under that provision; Any term or expression used but not defined herein or under the terms shall have the
      same meaning attributable to it under applicable law; References to the word "include" or "including" shall be
      construed without limitation.
    </p>

    <h2 class="text-darkBlue">DECLARATIONS</h2>
    <h3 class="text-darkBlue h4">Financial limits and Declarations</h3>
    <p>
      I hereby declare that: I am a resident Indian. I am not a holder of the Beneficiary's account to which the
      remittance is being made, except for remittances sent under the purpose of "remittance to own account abroad". The
      total amount of foreign exchange purchased from or remitted through, all sources in India during this financial
      year including this proposed remittance is within USD 250,000 (USD two hundred and fifty thousand only) the limit
      prescribed by Reserve Bank of India for the said purpose under the Foreign Exchange Management Act, 1999. Foreign
      exchange proposed to be remitted from CONTRACTED AD1/ AD2 India is for the purpose mentioned in this application.
      The transaction, details of which are specifically mentioned in the application, does not cross the limit for the
      financial year or any other limit prescribed by the Reserve AD1/ AD2 of India under the Foreign Exchange
      Management Act, 1999 for the said purpose. I am aware that the Finance Act, 2015 has amended Section 195(6) of the
      Income Tax Act, 1961 whereby the person responsible for making any payments to non-residents, whether or not
      chargeable to tax in India, is compulsorily required to furnish information in such form and manner as may be
      prescribed. I am aware of the penal provisions that would be applicable to me/us as a payer in case of
      non-compliance. However, the rules prescribing the forms have not been notified by Central Board of Direct Taxes
      (CBDT)/ Finance Ministry. Currently, Rule 37bb prescribing requirement for furnishing of Form 15ca and certificate
      in Form 15cb is applicable only in respect of payment chargeable to tax. In my view, the current Rule 37 bb is not
      applicable for payments which are not chargeable to tax and hence, I am unable to comply with the requirement for
      the payment not chargeable to tax until the new rules are notified by the CBDT/ Finance Ministry. I hereby confirm
      that amount to be remitted is not chargeable to be taxed in India. Therefore, Form 15ca and Form 15cb as per
      provisions of Rule 37bb of the Income Tax Rules, 1962 are not required to be submitted. In the event if there is
      any income tax query/inquiry/notice demanding details/information in India in respect of this particular
      remittance, I/we undertake to provide all the necessary information/document to CONTRACTED AD1/ AD2 India as
      required by the Income Tax Department. Further, I/we shall indemnify CONTRACTED AD1/ AD2 India for any
      tax/interest or penalty levied by the Income Tax/Income Tax Appellate Authorities/courts in India for
      non-deduction of tax or non-compliance with the relevant provisions of income tax law in regard to this
      remittance. I, hereby confirm that in case it has been identified at any time during the financial year, that I
      have breached the permissible limit of remittances under the extant regulations then CONTRACTED AD1/ AD2 India
      will not be responsible for any such breach by me and I agree to adhere to the penal provisions prescribed by the
      Reserve AD1/ AD2 of India in this regard. In the event if there is any enquiry from any statutory/regulatory
      authorities in respect of this particular remittance, I undertake to provide all the necessary
      information/document(s) to CONTRACTED AD1/ AD2 India as may be required in this regard. Further, I undertake to
      indemnify CONTRACTED AD1/ AD2 India for any tax/interest or penalty levied by the Income Tax/Income Tax Appellate
      Authorities/courts in India for non-deduction of tax or non-compliance with the relevant provisions of income tax
      law with regard to this remittance. I, being a Person resident in India, hereby declare that transaction proposed
      to be done under the liberalized remittance scheme, issued and amended by the Reserve AD1/ AD2 of India from time
      to time, is not in the nature of remittance for margins or margin calls to overseas exchanges/overseas counter
      party or for purpose prohibited transactions under the Foreign Exchange Management Act, 1999 or any rules,
      regulations or guidelines issued under the said act, like remittances for purchase of FCCBs issued by Indian
      companies in overseas secondary markets, remittance for foreign exchange trading abroad, etc.
    </p>

    <h2 class="text-darkBlue">Changes to These Terms and Conditions</h2>
    <h3 class="text-darkBlue h4">Financial limits and Declarations</h3>
    <p>
      I hereby declare that the proposed remittance does not involve, and is not designed for any purpose for which the
      drawal of foreign exchange is prohibited viz travel to Nepal and/or Bhutan, a transaction with a Person resident
      in Nepal or Bhutan and those under Rule 3 and transactions which require prior approval of the Central Government
      under Rule 4 of the Foreign Exchange Management (Current Account Transactions) Rules, 2000 read with Schedule I
      and Schedule II thereof viz
    </p>

    <h3 class="text-darkBlue h4">Prohibited transactions</h3>
    <p>
      Remittance out of lottery winnings. Remittance of income from racing/riding etc. or any other hobby. Remittance
      for purchase of lottery tickets, banned/proscribed magazines, football pools, sweepstakes, etc. Payment of
      commission on exports made towards equity investment in joint ventures / wholly owned subsidiaries abroad of
      Indian companies. Remittance of dividend by any company to which the requirement of dividend balancing is
      applicable. Payment of commission on exports under Rupee State Credit Route, except commission up to 10% of
      invoice value of exports of tea and tobacco. Payment related to "Call Back Services" of telephones. Remittance of
      interest income on funds held in Non-Resident Special Rupee (Account)
    </p>
    <h3 class="text-darkBlue h4">Transactions requiring prior approval of central government</h3>
    <h3 class="text-darkBlue h4">Other Declarations</h3>
    <p>
      I hereby declare that the purpose of remittance and transaction details as mentioned above are true to the best of
      my knowledge and does not involve, and is not designed for the purpose of any contravention or evasion of the
      provisions of the Foreign Exchange Management Act, 1999 or any rule, regulation, notification, direction or order
      made thereunder. I agree that I shall be responsible and liable for any incorrect detail provided by me. I also
      hereby agree and undertake to give such information/documents as will reasonably satisfy CONTRACTED AD1/ AD2 India
      about this transaction in terms of the above declaration. I further agree that once the funds remitted by me have
      been transmitted by CONTRACTED AD1/ AD2 India to the correspondent and/or Beneficiary AD1/ AD2s, CONTRACTED AD1/
      AD2 India shall not be responsible for any delays in the disbursement of such funds including the withholding of
      such funds by the correspondent and/or Beneficiary AD1/ AD2s. I agree that in the event the transaction gets
      rejected by the Beneficiary AD1/ AD2 because of any incorrect information submitted by me then any charges levied
      by the Beneficiary AD1/ AD2 and/or intermediary AD1/ AD2 and exchange loss incurred in this connection can be
      recovered by CONTRACTED AD1/ AD2 India from the amount returned by the correspondent AD1/ AD2. I also understand
      that if I refuse to comply with any such requirement or make any unsatisfactory compliance therewith, CONTRACTED
      AD1/ AD2 India shall refuse to undertake the transaction and shall, if it has reason to believe that any
      contravention/evasion is contemplated by me, report that matter to the Reserve AD1/ AD2 of India. I agree that in
      the event the transaction is cancelled or revoked by me after submitting the request for processing to CONTRACTED
      AD1/ AD2 India, which the AD1/ AD2 may accept at its discretion and on a best effort basis, any exchange loss
      incurred in this connection can be debited from the funds paid to CONTRACTED AD1/ AD2 India for executing the
      transactio.
    </p>
    <h3 class="text-darkBlue h4">Changes to These Terms and Conditions</h3>
    <p>
      We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is
      material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect.
      What constitutes a material change will be determined at Our sole discretion.
    </p>
    <p>
      By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the
      revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the
      Service.
    </p>
    <h3 class="text-darkBlue h4">Contact Us</h3>
    <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
    <p class="ml-3 mb-0">
      By email:
      <a href="mailto:support@mybeacon.ca"><span class="text-darkBlue font-italic">support&#64;mybeacon.ca</span> </a>
    </p>
    <p class="ml-3 mb-0">
      By visiting this page on our website:
      <a href="https://www.mybeacon.ca/contactUs.html"
        ><span class="text-darkBlue font-italic">www.mybeacon.ca/contactUs</span></a
      >
    </p>
  </div>
</div>
