import { Component, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MasterService } from "@app/shared/service/master.service";
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { interval, Subscription } from "rxjs";
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { FreshdeskWidgetService } from "@app/shared/service/freshdesk-widget.service";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.sass"],
})
export class LoginPageComponent implements OnInit {
  year: string;
  timer: string = "60";
  countryList: { countryCode: string, callingCode: string }[] = [];
  countryCodes: { [key: string]: string } = {};
  selectedCountryCode: string = 'CA';
  selectedCallingCode: string = '1';
  timerSubscription: Subscription;

  sentOtp: boolean = false;
  resentOtp: boolean = false;
  noEdit: boolean = true;

  phoneNo: string = "";
  otp: string = "";
  loading: boolean = false;
  phoneNoError: boolean = false;
  otpError: boolean = false;
  constructor(
    private MasterService: MasterService,
    private router: Router,
    public toasterService: ToastrService,
    private renderer: Renderer2,
    private freshdeskWidgetService: FreshdeskWidgetService,
  ) {
    let date = new Date();
    this.year = date.getFullYear().toString();
  }
  ngOnInit(): void {
    this.freshdeskWidgetService.hideWidget();
    this.loadCountryCallingCodes();
  }

  loadCountryCallingCodes() {
     const countries = getCountries();
    countries.forEach(country => {
      const callingCode = getCountryCallingCode(country);
      this.countryList.push({ countryCode: country, callingCode });
      this.countryCodes[country] = callingCode;
    });
  }

  onCountryChange() {
    this.selectedCallingCode = this.countryCodes[this.selectedCountryCode];
    console.log(`Calling Code: +${this.selectedCallingCode}`);
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.freshdeskWidgetService.showWidget();
  }

  slideConfig2 = {
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "0",
    slidesToShow: 1,
    arrows: false,
    speed: 500,
    dots: false,
    autoplay: true,
  };

  startTimer(): void {
    this.timerSubscription = interval(1000).subscribe(() => {
      if (+this.timer > 0) {
        this.timer = (+this.timer - 1).toString();
        if (+this.timer < 10) {
          this.timer = "0" + this.timer;
        }
      } else {
        this.timerSubscription.unsubscribe();
        if (this.sentOtp) this.resentOtp = true;
      }
    });
  }

  sendOtp(): void {
    this.phoneNoError = false;
    if (!this.phoneNo || this.phoneNo.length < 10) {
      this.phoneNoError = true;
    } else {
      console.log("OTP Sent");

      this.loading = true;
      let temp: any = {};
      temp.phoneNo = `+${this.selectedCallingCode} ${this.phoneNo}`;
      this.MasterService.loginOtp(temp).subscribe(
        (res) => {
          this.toasterService.success(res?.msg, "Success", {
            positionClass: "toast-top-right",
          });
          this.timer = "60";
          this.resentOtp = false;
          this.sentOtp = true;
          this.noEdit = false;
          this.startTimer();
          this.loading = false;
        },
        (err) => {
          console.log("Some error occured" + JSON.stringify(err));
          this.toasterService.error(this.MasterService.getError(err), "Error", {
            positionClass: "toast-top-right",
          });
          this.loading = false;
        },
      );
    }
  }
  editNo() {
    this.timerSubscription.unsubscribe();
    this.resentOtp = false;
    this.sentOtp = false;
    this.noEdit = true;
  }

  login(): void {
    console.log("OTP submit" + this.otp + "   vvv" + (this.otp.length < 7));
    this.otpError = false;
    if (!this.otp || this.otp.length < 6) {
      this.otpError = true;
    } else {
      console.log("OTP submit");
      let callingCode = ""
      this.loading = true;
      let temp: any = {};
      if (this.selectedCallingCode.length === 1) {
        callingCode = `+ ${this.selectedCallingCode}`;
      } else {
        callingCode = `+${this.selectedCallingCode}`;
      }
      temp.phoneNo = `${callingCode} ${this.phoneNo}`;
      temp.otp = this.otp;
      this.MasterService.getToken(temp).subscribe(
        (res) => {
          console.log("Login Validation Staart");
          localStorage.setItem("bearer", res.accessToken);
          this.MasterService.getUser().subscribe((res) => {
            console.log("userInfo" + JSON.stringify(res.lrsUser));
            localStorage.setItem("user", JSON.stringify(res.lrsUser));
            localStorage.setItem("name", JSON.stringify(res.userName));
            if (res) {
              let url = "customer/dashboard";
              this.router.navigateByUrl("app/pages/" + url);
            }
          });

          console.log("Login Validation end");
          //this.router.navigateByUrl("");
        },
        (err) => {
          console.log("Some error occured" + JSON.stringify(err));
          this.toasterService.error(this.MasterService.getError(err), "Error", {
            positionClass: "toast-top-right",
          });
          this.loading = false;
        },
      );
    }
  }

  checkDefault() {
    // if (!this.phoneNo || this.phoneNo.length == 0) {
    //   this.phoneNo = "+91 ";
    // }
  }

  redirectToDashboard() {
    this.router.navigateByUrl("/pages/login");
  }
  signUp() {
    this.router.navigateByUrl("/pages/signUpLRS");
  }
}
