<div class="d-flex">
  <div class="header-btn-lg pr-0">
    <div class="widget-content p-0">
      <div class="widget-content-wrapper">
        <div class="widget-content-left ml-6">
          <!-- <div class="btn-group" ngbDropdown placement="bottom-right">
            <button
              type="button"
              class="btn btn-link p-0 mr-2"
              ngbDropdownToggle
            >
            <div class="widget-content-wrapper header-user-info">
              <div class="circle widget-content-left ">
                <p class="circle-inner" style="background-color: #E1CCFF !important; color: white !important;"><span><i class="las fa fa-bell"></i></span></p>
              </div>
            </div>
            </button>
          </div> -->
          <div class="btn-group" ngbDropdown placement="bottom-right">
            <button type="button" class="btn btn-link p-0 mr-sm-2 mr-0" ngbDropdownToggle>
              <div class="widget-content-wrapper header-user-info">
                <div class="circle widget-content-left">
                  <!-- <p class="circle-inner">{{ user?.userName | slice:0:1 | uppercase }}</p> -->
                  <p class="circle-inner">
                    <span><i class="las fa fa-user"></i></span>
                  </p>
                </div>
                <div class="widget-heading" style="font-size: larger; color: #474545">
                  {{ user?.userName }}
                </div>
              </div>
            </button>
            <div class="dropdown-menu-lg dropdown-mobile" ngbDropdownMenu>
              <div class="dropdown-menu-header">
                <div class="dropdown-menu-header-inner" style="background: #244349">
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <!-- <img
                            width="42"
                            src="./assets/images/avatars/1.jpg"
                            alt=""
                            class="rounded-circle"
                          /> -->
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">
                            {{ user.clientDet?.clientName }}
                          </div>
                          <div class="widget-subheading opacity-8">
                            {{ user?.userType }}
                          </div>
                        </div>
                        <!-- <div class="widget-content-right mr-2">
                          <button
                            (click)="logout()"
                            class="btn-pill btn-shadow btn-shine btn btn-white"
                          >
                            Logout
                          </button>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="scroll-area-xs">
                <perfect-scrollbar [autoPropagation]="true">
                  <ul class="nav flex-column">
                    <!-- <li class="nav-item-header nav-item">Activity</li> -->
                    <!-- <li class="nav-item"><a [routerLink]="" class="nav-link">Chat
                        <div class="ml-auto badge badge-pill badge-info">8</div>
                      </a></li> -->
                    <!-- <li class="nav-item">
                      <a (click)="changePassword()" class="nav-link"
                        ><i class="las fa-key text-dark mr-1"></i>Change Password</a
                      >
                    </li> -->
                    <!-- <li class="nav-item">
                      <a (click)="updateConfig()" class="nav-link"
                        >Configuration Details</a
                      >
                    </li> -->
                    <!-- <li class="nav-item">
                      <a (click)="redirectToUserProfile()" class="nav-link"><i class="las fa-user text-dark mr-1"></i>Profile</a>
                    </li> -->

                    <li class="nav-item">
                      <a (click)="redirectToMyRemittances()" class="nav-link"
                        ><i class="las fa-credit-card text-dark mr-1"></i>My Remittances</a
                      >
                    </li>

                    <li class="nav-item">
                      <a (click)="redirectToKYCDashboard()" class="nav-link"
                        ><i class="las fa-id-card text-dark mr-1"></i>KYC</a
                      >
                    </li>

                    <li class="nav-item">
                      <a (click)="logout()" class="nav-link"><i class="las fa fa-sign-out text-dark mr-1"></i>Logout</a>
                    </li>
                  </ul>
                </perfect-scrollbar>
              </div>
              <!-- <ul class="nav flex-column">
                <li class="nav-item-divider mb-0 nav-item"></li>
              </ul> -->
              <!-- <div class="grid-menu grid-menu-2col overflow-hidden">
                <div class="no-gutters row">
                  <div class="col-sm-6">
                    <button class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-warning">
                      <i class="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"></i>
                      Message Inbox
                    </button>
                  </div>
                  <div class="col-sm-6">
                    <button class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-danger">
                      <i class="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"></i>
                      <b>Suppor Tickets</b>
                    </button>
                  </div>
                </div>
              </div> -->
              <!-- <ul class="nav flex-column">
                <li class="nav-item-divider nav-item"></li>
                <li class="nav-item-btn text-center nav-item">
                  <button class="btn-wide btn btn-primary btn-sm">Open Messages</button>
                </li>
              </ul> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
